import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";

import { fadeOut, slideInToaster } from "../../core-ui.animations";
import { ConferenceStore, ThemeService, UserService } from "../../services";
import { IQueueAsset, ThemeParam } from "../../../core-ui/models";
import {
  AssetTypeEnum,
  ConversationTypeEnum,
} from "../../../core-ui/core-ui.enums";
import { IInteraction } from "../../models";

@Component({
  selector: "app-hold",
  templateUrl: "./hold.component.html",
  styleUrls: ["./hold.component.scss"],
  animations: [slideInToaster, fadeOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldComponent implements OnInit {
  @Input() interaction: IInteraction;
  @Input() enabled: boolean;
  @Input() videoReady: boolean;

  @ViewChild("videoRef") videoRef: ElementRef<HTMLVideoElement>;

  isImageAvailable = false;
  isVideoAvailable = false;
  imageSrc: string;
  videoSrc: string;

  constructor(
    private store: ConferenceStore,
    private user: UserService,
    private theme: ThemeService
  ) {}

  ngOnInit() {
    if (this.user.isCustomer) {
      this.theme.themeChanged$().subscribe((t) => {
        if (
          t[ThemeParam.CALL_HOLD_ASSET_ENABLED] &&
          t[ThemeParam.CALL_HOLD_ASSETS].length > 0
        ) {
          const asset: IQueueAsset = t[ThemeParam.CALL_HOLD_ASSETS][0];
          switch (asset.type) {
            case AssetTypeEnum.image:
              this.isImageAvailable = !this.isVoiceCall && !!asset.url;
              this.imageSrc = asset.url;
              break;
            case AssetTypeEnum.video:
              this.isVideoAvailable = !this.isVoiceCall && !!asset.url;
              this.videoSrc = asset.url;
              break;
          }
        }
      });
    }
  }

  canPlayThrough() {
    // try to play
    this.videoRef.nativeElement.play()?.catch((_) => {
      // mute and try to play again
      this.videoRef.nativeElement.muted = true;
      this.videoRef.nativeElement.play().catch((_) => {
        // check every 3 seconds if user has interacted with page and try to play again
        let playAttempt = setInterval(() => {
          this.videoRef.nativeElement.play()?.then(() => {
            clearInterval(playAttempt);
          });
        }, 3000);
      });
    });
  }

  imgLoadFailed() {
    this.isImageAvailable = false;
  }

  get isVoiceCall() {
    return this.interaction.getType() === ConversationTypeEnum.voiceCall;
  }

  get stream() {
    return this.store.mystream;
  }
}
