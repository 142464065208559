import { Injectable, NgZone } from "@angular/core";
import { isBackgroundSupported } from "@auvious/compatibility";
import {
  BackgroundError,
  BackgroundFilter,
  ImageUnusable,
  ImageSegmenter,
} from "@auvious/media-tools";
import { IPublishedStream, StreamTypes } from "@auvious/rtc";
import { localStore } from "@auvious/utils";
import { BehaviorSubject, of, Subject } from "rxjs";

import {
  AssetCategoryEnum,
  KEY_MEDIA_VIDEO_EFFECT,
  MediaFilterTypeEnum,
  StreamTrackKindEnum,
} from "../core-ui.enums";
import {
  AgentParam,
  IInteraction,
  IMediaFilter,
  IStreamMetadata,
} from "../models";
import { AppConfigService, FEATURES } from "./app.config.service";
import { ApplicationService } from "./application.service";
import { ConferenceService } from "./conference.service";
import { DeviceService } from "./device.service";
import { GenericErrorHandler } from "./error-handlers.service";
import { LocalMediaService } from "./local.media.service";
import { StorageAssetService } from "./storage.asset.service";
import { UserService } from "./user.service";
import { createSentryLogger, debugError } from "./utils";
import { AuviousRtcService } from "./rtc.service";

type MediaEffectErrorType =
  | "ImageUnusable"
  | "VideoElementNotFound"
  | "VideoTrackNotFound"
  | "EffectFailed"
  | "PublishFailed"
  | "UnpublishFailed";

const sentryLog = createSentryLogger("media.effects");

@Injectable()
export class MediaEffectsService {
  private url = "core-ui/assets/media-filter-backgrounds";

  private effect: BackgroundFilter | null = null;
  private selectedFilter: IMediaFilter;
  private image?: HTMLImageElement = null;

  private segmenter = new ImageSegmenter(
    window.origin + "/assets/media-filters"
  );

  private isActive = false;
  private loading = false;

  private appFiltersLoaded = false;
  private storageFiltersLoaded = false;

  private effects: IMediaFilter[] = [
    { id: "1", type: MediaFilterTypeEnum.none },
    { id: "2", type: MediaFilterTypeEnum.backgroundBlur },
    {
      id: "3",
      type: MediaFilterTypeEnum.backgroundImage,
      url: of(`${this.url}/library-1.jpg`),
    },
    {
      id: "4",
      type: MediaFilterTypeEnum.backgroundImage,
      url: of(`${this.url}/library-2.jpg`),
    },
    {
      id: "6",
      type: MediaFilterTypeEnum.backgroundImage,
      url: of(`${this.url}/living-room-2.jpg`),
    },
    {
      id: "7",
      type: MediaFilterTypeEnum.backgroundImage,
      url: of(`${this.url}/living-room-3.jpg`),
    },
    {
      id: "10",
      type: MediaFilterTypeEnum.backgroundImage,
      url: of(`${this.url}/ruins-1.jpg`),
    },
    {
      id: "11",
      type: MediaFilterTypeEnum.backgroundImage,
      url: of(`${this.url}/art-1.jpg`),
    },
  ];

  private _filterTypeChanged = new BehaviorSubject<IMediaFilter>(
    this.effects[0]
  );
  public filterTypeChanged$ = this._filterTypeChanged.asObservable();

  private _filterLoading = new Subject<MediaFilterTypeEnum>();
  public filterLoading$ = this._filterLoading.asObservable();

  private _filterLoaded = new Subject<void>();
  public filterLoaded$ = this._filterLoaded.asObservable();

  private _filtersUpdated = new BehaviorSubject<IMediaFilter[]>([]);
  public filtersUpdated$ = this._filtersUpdated.asObservable();

  private _filterDisabled = new Subject<void>();
  public filterDisabled$ = this._filterDisabled.asObservable();

  private _filterError = new Subject<MediaEffectErrorType>();
  public filterError$ = this._filterError.asObservable();

  constructor(
    private zone: NgZone,
    private conference: ConferenceService,
    private config: AppConfigService,
    private errorHandler: GenericErrorHandler,
    private application: ApplicationService,
    private asset: StorageAssetService,
    private user: UserService,
    private local: LocalMediaService,
    private rtc: AuviousRtcService
  ) {
    // caused effect to be removed when screen sharing and calling setFilter multiple times
    this.local.localStreamReady$.subscribe((stream) => {
      if (
        this.enabled &&
        stream &&
        (stream.getMetadata() as IStreamMetadata).primary &&
        (stream.type === StreamTypes.CAM || stream.type === StreamTypes.VIDEO)
      ) {
        this.setFilter(this.selectedFilter, this.image);
      }
    });

    this.local.streamRemoved$.subscribe((stream) => {
      if (
        // stream type transition
        !this.local.hasLiveInput(StreamTrackKindEnum.video) &&
        (stream?.type === StreamTypes.CAM || stream?.type === StreamTypes.VIDEO)
      ) {
        this.disable();
      }
    });

    this.local.streamMutedChange$.subscribe((event) => {
      if (
        this.effect &&
        event.trackKind === StreamTrackKindEnum.video &&
        (event.stream?.type === StreamTypes.CAM ||
          event.stream?.type === StreamTypes.VIDEO)
      ) {
        if (event.muted) {
          this.pause();
        } else {
          this.play();
        }
      }
    });
  }

  public async init(interaction: IInteraction) {
    try {
      this.loadAppFilters();
    } catch (ex) {
      debugError(ex);
    }

    try {
      await this.loadAssetFilters();
    } catch (ex) {
      debugError(ex);
    }

    const existingBackgroundEffect =
      this.getDefaultBackgroundEffect(interaction) ||
      this.getStoredBackgroundEffect();

    const existsInEffects = this.effects.some(
      (f) => f.id === existingBackgroundEffect?.id
    );
    if (existsInEffects) {
      this._filterTypeChanged.next(existingBackgroundEffect);
    } else {
      // probably deleted by an admin, so we need to remove it from our local storage
      localStore.removeItem(KEY_MEDIA_VIDEO_EFFECT);
    }
  }

  private getDefaultBackgroundEffect(interaction: IInteraction): IMediaFilter {
    let existingEffectId;

    const isPerQueue =
      this.config.agentParamEnabled(
        AgentParam.BACKGROUND_EFFECT_PER_QUEUE_ENABLED
      ) && !!interaction?.getQueueId();

    if (isPerQueue) {
      existingEffectId = Object.keys(
        this.config.agentParam(AgentParam.BACKGROUND_EFFECT_PER_QUEUE)
      ).find(
        (key) =>
          this.config.agentParam(AgentParam.BACKGROUND_EFFECT_PER_QUEUE)[
            key
          ] === interaction.getQueueId()
      );
    } else {
      // load effect from config file
      existingEffectId = this.config.agentParam(
        AgentParam.DEFAULT_BACKGROUND_EFFECT_ID
      );
    }
    return !!existingEffectId
      ? this.effects.find((f) => f.id === existingEffectId)
      : null;
  }

  private getStoredBackgroundEffect(): IMediaFilter {
    // load effect from local storage
    const existingEffectStr = localStore.getItem(KEY_MEDIA_VIDEO_EFFECT);

    if (existingEffectStr) {
      try {
        return JSON.parse(existingEffectStr);
      } catch (ex) {
        this.errorHandler.handleError(ex);
      }
    }

    return null;
  }

  private loadAppFilters() {
    if (!this.appFiltersLoaded) {
      this.appFiltersLoaded = true;

      this.effects = this.effects.concat(
        this.application.getActiveApplication().getFilterBackgrounds() || []
      );

      this._filtersUpdated.next(this.effects);
    }
  }

  public unloadAssetFilters() {
    this.storageFiltersLoaded = false;
  }

  private async loadAssetFilters() {
    if (!this.storageFiltersLoaded && this.user.isAgent) {
      this.storageFiltersLoaded = true;

      // remove the stock backrounds if disabled in params
      this.effects = this.config.agentParamEnabled(
        AgentParam.DEFAULT_BACKGROUND_EFFECTS_ENABLED
      )
        ? this.effects
        : this.effects.filter(
            (f) => f.type !== MediaFilterTypeEnum.backgroundImage
          );

      // merge the assets effects with the existing effects
      this.effects = this.effects.concat(
        (await this.asset.getAssets())
          .filter((a) => a.category === AssetCategoryEnum.background)
          .map((a) => ({
            id: a.id,
            type: MediaFilterTypeEnum.backgroundImage,
            get url() {
              // this refreshes expired links
              return a.signedUrl;
            },
          }))
      );

      this._filtersUpdated.next(this.effects);
    }
  }

  /** whether to self activate on new local video tracks */
  public get enabled(): boolean {
    return (
      this.selectedFilter &&
      this.selectedFilter.type !== MediaFilterTypeEnum.none
    );
  }

  /** create effect if not already present */
  private async load() {
    return this.effect
      ? null
      : this.zone.runOutsideAngular(async () => {
          try {
            this.effect = new BackgroundFilter(
              this.rtc.getAuviousCommonClient().getTimers()
            );
            this.effect.events.on("fail", this.onFilterFail.bind(this));

            await this.effect.load({
              assetsPath: location.origin + "/assets/media-filters/",
              useWebgl: false,
              lite: false,
            });
            if (this.effect) {
              this.local.pipe.addEffect(this.effect);
            }
          } catch (ex) {
            this.onFilterFail(ex);
            throw ex;
          }
        });
  }

  public async segmentImage(img: HTMLImageElement) {
    if (!this.segmenter.loaded) {
      await this.segmenter.load();
    }

    return this.segmenter.segment(img);
  }

  public async disable() {
    if (this.effect) {
      this.effect.unload();
      this.effect = null;
      this.isActive = false;
      this._filterDisabled.next();
    }
    this._filterTypeChanged.next(null);
  }

  private play() {
    if (this.effect) {
      this.zone.runOutsideAngular(() => this.effect.play());
      this.isActive = true;
    }
  }

  private pause() {
    this.effect?.pause();
    this.isActive = false;
  }

  public async setFilter(mediaFilter: IMediaFilter, image?: HTMLImageElement) {
    const streamType = this.local.mainStream.stream?.type;

    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      this._filterLoading.next(mediaFilter.type);

      if (!mediaFilter || mediaFilter.type === MediaFilterTypeEnum.none) {
        localStore.removeItem(KEY_MEDIA_VIDEO_EFFECT);
        await this.disable();
      } else if (this.isMediaFilterOn && mediaFilter === this.selectedFilter) {
        if (
          mediaFilter.type === MediaFilterTypeEnum.backgroundBlur ||
          !image?.src ||
          image.src === this.image?.src
        ) {
          return;
        }

        await this.effect?.setImage(image);
      } else if (
        streamType === StreamTypes.CAM ||
        streamType === StreamTypes.VIDEO
      ) {
        const video =
          this.local.mainStream.stream?.mediaStream?.getVideoTracks()[0];

        await this.load();

        if (mediaFilter.type === MediaFilterTypeEnum.backgroundBlur) {
          this.effect.unsetImage();
        } else if (image) {
          await this.effect.setImage(image);
        }

        localStore.setItem(
          KEY_MEDIA_VIDEO_EFFECT,
          JSON.stringify({ ...mediaFilter, url: null })
        );

        // not muted
        if (video && video.readyState === "live" && video.enabled) {
          this.play();
        }
      }

      if (mediaFilter?.id !== this.selectedFilter?.id) {
        this._filterTypeChanged.next(mediaFilter);
      }

      this.selectedFilter = mediaFilter;
      this.image = image;
    } catch (ex) {
      if (!(ex instanceof ImageUnusable)) {
        this.errorHandler.handleError(ex);
      } else {
        sentryLog(["ImageUnusable", image.src, image.complete]);
      }
    } finally {
      this.loading = false;
      this._filterLoaded.next();
    }
  }

  private get noneEffect() {
    return this.effects[0];
  }

  private get blurEffect() {
    return this.effects[1];
  }

  private onFilterFail(error: BackgroundError) {
    this.errorHandler.handleError(error);
    this.isActive = false;
    this.loading = false;
    this.setFilter(this.noneEffect);

    this._filterError.next(
      error instanceof ImageUnusable ? "ImageUnusable" : "EffectFailed"
    );
  }

  public get supportsMediaFilters() {
    return (
      this.config.featureEnabled(FEATURES.MEDIA_FILTERS) &&
      !DeviceService.isMobile &&
      !DeviceService.isSafari &&
      isBackgroundSupported()
    );
  }

  public setPortraitModeId(streamId: string, value: boolean) {
    const stream = this.conference.getStream(streamId);

    if (stream) {
      this.conference.updateStreamMetadata(stream as IPublishedStream, {
        portraitMode: value,
      });
    }
  }

  public isEnabled() {
    return this.enabled;
  }

  public get isMediaFilterOn() {
    return this.isActive;
  }
}
