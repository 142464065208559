import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { StreamTypes } from "@auvious/rtc";
import { Subscription } from "rxjs";
import {
  ConferenceService,
  debug,
  debugError,
  GenericErrorHandler,
} from "../../services";

@Component({
  selector: "app-stream-view",
  templateUrl: "./stream-view.component.html",
  styleUrls: ["./stream-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * uses an object-fit polyfill
 * https://github.com/constancecchen/object-fit-polyfill
 */
export class StreamViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: StreamTypes;
  @Input() muted: boolean;
  @Input() id: string;

  @Output() mediaElementReady = new EventEmitter<
    HTMLAudioElement | HTMLVideoElement
  >();
  @Output() mediaElementDestroyed = new EventEmitter<{
    element: HTMLAudioElement | HTMLVideoElement;
    id: string;
  }>();
  @Output() mediaElementPlayError = new EventEmitter<string>();
  @Output() autoplayBlocked = new EventEmitter<string>();

  @ViewChild("streamsContainer", { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  @ViewChild("audioTagTpl", { static: true }) audioTemplate: TemplateRef<any>;
  @ViewChild("videoTagTpl", { static: true }) videoTemplate: TemplateRef<any>;

  public mediaElement: HTMLVideoElement | HTMLAudioElement;

  private _stream: MediaStream;
  private subscription = new Subscription();

  @Input()
  set stream(value: MediaStream) {
    this._stream = value;
    debug("stream-view new mediastream", this.stream);

    if (this.mediaElement && this.mediaElement.srcObject !== this.stream) {
      this.mediaElement.srcObject = this.stream;
    }
  }

  get stream() {
    return this._stream;
  }

  constructor(
    private errorHandler: GenericErrorHandler,
    private conferenceService: ConferenceService
  ) {
    this.subscription.add(
      this.conferenceService.playPausedStreams$.subscribe(() => {
        this.play();
      })
    );
  }

  private async play() {
    if (!this.isPaused) {
      return true;
    }

    debug("playing element :" + this.id);

    try {
      await this.mediaElement?.play();
      return true;
    } catch (ex) {
      debugError("play() error", ex);

      if (/^NotAllowedError/.test(ex)) {
        this.autoplayBlocked.emit(this.id);
      } else {
        this.errorHandler.handleError(ex);
      }

      return false;
    }
  }

  ngOnDestroy() {
    this.mediaElementDestroyed.emit({
      element: this.mediaElement,
      id: this.id,
    });

    this.subscription.unsubscribe();
  }

  ngOnInit() {}

  // creates audio and video if local and is video type or one for remote
  private createElements(): HTMLAudioElement | HTMLVideoElement | null {
    let element: HTMLAudioElement | HTMLVideoElement;

    if (this.type === StreamTypes.MIC) {
      element = this.container
        .createEmbeddedView(this.audioTemplate)
        .rootNodes.find((el) => el.nodeName === "AUDIO");
    } /* if (
      this.type === StreamTypes.CAM ||
      this.type === StreamTypes.VIDEO ||
      this.type === StreamTypes.SCREEN
    ) */ else {
      element = this.container
        .createEmbeddedView(this.videoTemplate)
        .rootNodes.find((el) => el.nodeName === "VIDEO");
    }

    return element;
  }

  ngAfterViewInit() {
    try {
      this.mediaElement = this.createElements();

      this.mediaElement.addEventListener(
        "loadedmetadata",
        () => {
          setTimeout(() => this.play(), 50);
        },
        {
          once: true,
        }
      );

      this.mediaElement.addEventListener("pause", () => {
        setTimeout(() => this.play(), 100);
      });

      if (this.stream /* !== this.conferenceService.localMediaStream */) {
        this.mediaElement.srcObject = this.stream;
      }

      this.mediaElementReady.emit(this.mediaElement);
    } catch (ex) {
      debugError(ex);
    }
  }

  private checkPlayback() {
    return new Promise<boolean>((resolve) => {
      const currentTimeA = this.mediaElement?.currentTime;
      const qualA = (
        this.mediaElement as HTMLVideoElement
      )?.getVideoPlaybackQuality?.();

      setTimeout(() => {
        const currentTimeB = this.mediaElement?.currentTime;
        const qualB = (
          this.mediaElement as HTMLVideoElement
        )?.getVideoPlaybackQuality?.();

        resolve(
          currentTimeB !== currentTimeA &&
            (!qualA || qualB.totalVideoFrames - qualA.totalVideoFrames > 0)
        );
      }, 600);
    });
  }

  get isPaused() {
    return this.mediaElement?.paused /* &&
      this.mediaElement.readyState < this.mediaElement.HAVE_FUTURE_DATA */;
  }
}
