<div
  #point
  class="point"
  (click)="clicked($event)"
  [ngStyle]="{ 'left.px': position.left, 'top.px': position.top }"
  [@zoomInOut]="true"
  date-tid="point/point"
>
  {{ data.index }}

  <div class="progressRing" #ring>
    <!-- <div [ngStyle]="{ 'width': (normalizedRadius * 2 + stroke), 'height': (normalizedRadius * 2 + stroke), 'borderWidth': stroke }"
            class="progressRingFull"></div> -->
    <svg #svg>
      <circle #circle fill="transparent" />
    </svg>
  </div>
</div>
