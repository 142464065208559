import { ConversationDestinationEnum } from "../../core-ui";
import { IInteraction } from "../../core-ui/models/IInteraction";
import { IConversationInfo } from "./IConversationInfo";
import {
  ConversationMetadataEnum,
  ConversationChannelEnum,
  OriginModeEnum,
  ConversationTypeEnum,
  ConversationOriginEnum,
  WhisperModeEnum,
} from "../../core-ui/core-ui.enums";
import { mapToJSON } from "../app.utils";
import {
  DEFAULT_CUSTOMER_ID,
  KEY_ROOM_NAME,
  KEY_TICKET_ID,
  PARAM_CALL_TYPE,
} from "../app.enums";

export class VerintInteraction implements IInteraction {
  private _info: IConversationInfo;
  private _room: string;
  private _parentFrameUrl: string;
  private _destination: ConversationDestinationEnum;
  private _meta: Map<ConversationMetadataEnum, any>;
  private _whisperMode: WhisperModeEnum;

  constructor(data: IConversationInfo) {
    this._info = { ...data };
    if (!data) {
      return;
    }
    if (!!data.room) {
      this._room = data.room;
    }
    this._destination = ConversationDestinationEnum.EMBEDDED;

    this._meta = new Map();
    // @ts-expect-error
    if (data.metadata) {
      // @ts-expect-error
      Object.keys(data.metadata).forEach((key) =>
        // @ts-expect-error
        this._meta.set(key, data.metadata[key])
      );
    }

    if (data.customerMetadata) {
      Object.keys(data.customerMetadata)
        .filter((key) => ![KEY_ROOM_NAME, KEY_TICKET_ID].includes(key))
        .forEach((key) =>
          this._meta.set(
            key as ConversationMetadataEnum,
            data.customerMetadata[key]
          )
        );
    }
  }

  getId(): string {
    return "a";
  }
  getIntegrationId(): string {
    throw new Error("Method not implemented.");
  }
  hasRoom(): boolean {
    return !!this._room;
  }
  getRoom(): string {
    return this._room;
  }
  setRoom(room: string) {
    this._room = room;
  }
  getOrigin(): ConversationOriginEnum {
    return ConversationOriginEnum.WIDGET;
  }
  setOrigin(value: ConversationOriginEnum) {
    throw new Error("Method not implemented.");
  }
  getType(): ConversationTypeEnum {
    return ConversationTypeEnum.videoCall;
  }
  setType(value: ConversationTypeEnum) {
    throw new Error("Method not implemented.");
  }
  getCustomerId(): string {
    return this._info.customerId;
  }
  getCustomerEmail(): string {
    return null;
  }
  getCustomerName(): string {
    return null;
  }
  getCustomerPhoneNumber(): string {
    throw new Error("Method not implemented.");
  }
  setParentFrameUrl(value: string) {
    throw new Error("Method not implemented.");
  }
  getParentFrameUrl(): string {
    throw new Error("Method not implemented.");
  }
  getOriginMode(): OriginModeEnum {
    return OriginModeEnum.website;
  }
  setOriginMode(value: OriginModeEnum) {
    throw new Error("Method not implemented.");
  }
  getDestination(): ConversationDestinationEnum {
    return ConversationDestinationEnum.EMBEDDED;
  }
  setDestination(value: ConversationDestinationEnum) {
    throw new Error("Method not implemented.");
  }
  setMetadataItem(key: ConversationMetadataEnum, value: any) {
    throw new Error("Method not implemented.");
  }
  getMetadataItem(key: ConversationMetadataEnum) {
    throw new Error("Method not implemented.");
  }
  getMetadata() {
    return mapToJSON(this._meta);
  }
  getChannel(): ConversationChannelEnum {
    throw new Error("Method not implemented.");
  }
  setChannel(value: ConversationChannelEnum) {
    throw new Error("Method not implemented.");
  }
  getAppointmentId(): string {
    return null;
  }
  setAppointmentId(value: string) {
    throw new Error("Method not implemented.");
  }
  setCustomerId(value: string) {
    throw new Error("Method not implemented.");
  }
  getQueueId(): string {
    throw new Error("Method not implemented.");
  }
  supportsMessages(): boolean {
    return true;
  }
  supportsEmails(): boolean {
    return false;
  }
  supportsSMS(): boolean {
    return false;
  }
  supportsScheduledCalls(): boolean {
    return false;
  }
  isCustomerInvited(): boolean {
    return false;
  }
  isTransfer(): boolean {
    return false;
  }
  isIntegrationInteractionAvailable(): boolean {
    return this._info.customerId !== DEFAULT_CUSTOMER_ID;
  }
  toString?(): string {
    throw new Error("Method not implemented.");
  }
  getCustomerLink(ticketUrl: string): string {
    return ticketUrl;
  }
  setWhisperMode(value: WhisperModeEnum) {
    this._whisperMode = value;
  }
  getWhisperMode(): WhisperModeEnum {
    return this._whisperMode;
  }
}
