import { IInteraction, ITicketRequest } from "../../../core-ui/models";
import { IInvitationStrategy } from "../../../core-ui/models/strategies";

export class NoInvitationStrategy implements IInvitationStrategy {
  prepareScheduleTicketRequest(): ITicketRequest {
    return null;
  }
  prepareTicketRequest(): ITicketRequest {
    return null;
  }
  prepareAppointmentTicketRequest(): ITicketRequest {
    return null;
  }
  prepareAgentInvitation() {
    return null;
  }
  prepareSupervisorInvitation(interaction: IInteraction): string {
    return null;
  }
}
