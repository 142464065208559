import { PureCloudService } from "../../../services/purecloud.service";

import { IInteraction } from "../../../../core-ui/models/IInteraction";
import { IAnalyticsStrategy } from "../../../../core-ui/models/strategies";
import {
  DEFAULT_CUSTOMER_ID,
  KEY_ROOM_NAME,
  TAG_CUSTOMER_ENDED_AT,
  TAG_CUSTOMER_INVITED,
  TAG_CUSTOMER_RECORDING_INSTANCE_ID,
  TAG_CUSTOMER_RECORDING_RECORDER_ID,
  TAG_CUSTOMER_REQUESTED_AT,
  TAG_CUSTOMER_MONITOR_URL,
  TAG_CUSTOMER_TICKET_SENT_AT,
} from "../../../app.enums";
import { IRecorderInfo, IRecorderSessionInfo } from "../../../../core-ui";
import { TAG_CUSTOMER_TRANSFER_ROOM } from "../../../app.enums";

export class GenesysCloudAnalyticsStrategy implements IAnalyticsStrategy {
  constructor(private pureCloudService: PureCloudService) {}

  private isValidInteraction(interaction: IInteraction): boolean {
    return (
      !!interaction &&
      !!interaction.getCustomerId() &&
      interaction.getCustomerId() !== DEFAULT_CUSTOMER_ID
    );
  }

  trackCallRequested(
    interaction: IInteraction,
    extra: Partial<{ supervisorUrl: string }>
  ) {
    if (!this.isValidInteraction(interaction)) {
      return;
    }

    this.pureCloudService.tagCustomer(
      interaction.getId(),
      interaction.getCustomerId(),
      {
        [TAG_CUSTOMER_REQUESTED_AT]: new Date(),
        [TAG_CUSTOMER_MONITOR_URL]: extra.supervisorUrl || "",
      }
    );
  }

  trackInvitationSent(interaction: IInteraction): void {
    if (!this.isValidInteraction(interaction)) {
      return;
    }

    this.pureCloudService.tagCustomer(
      interaction.getId(),
      interaction.getCustomerId(),
      {
        [TAG_CUSTOMER_TICKET_SENT_AT]: new Date(),
        [TAG_CUSTOMER_INVITED]: true,
        [KEY_ROOM_NAME]: interaction.getRoom(),
      }
    );
  }

  trackCallEnding(interaction: IInteraction): Promise<void> {
    if (!this.isValidInteraction(interaction)) {
      return;
    }

    return this.pureCloudService.tagCustomer(
      interaction.getId(),
      interaction.getCustomerId(),
      {
        // we must clear the ticket sent, in case a second agent wants to send the ticket
        [TAG_CUSTOMER_INVITED]: false,
      }
    );
  }

  trackCallEnded(interaction: IInteraction): Promise<void> {
    if (!this.isValidInteraction(interaction)) {
      return;
    }

    return this.pureCloudService.tagCustomer(
      interaction.getId(),
      interaction.getCustomerId(),
      {
        [TAG_CUSTOMER_ENDED_AT]: new Date(),
        // if the agent terminated the call, we must update with a null room name
        // so that the agent can join again if he wants by creating a new room
        [KEY_ROOM_NAME]: interaction.getRoom() || "",
      }
    );
  }

  trackConferenceRoomEnded(interaction: IInteraction): Promise<void> {
    if (!this.isValidInteraction(interaction)) {
      return;
    }
    return this.pureCloudService.tagCustomer(
      interaction.getId(),
      interaction.getCustomerId(),
      {
        [KEY_ROOM_NAME]: "",
      }
    );
  }

  trackCallTransferred(interaction: IInteraction): void {
    if (!this.isValidInteraction(interaction)) {
      return;
    }
    this.pureCloudService.tagCustomer(
      interaction.getId(),
      interaction.getCustomerId(),
      {
        [TAG_CUSTOMER_TRANSFER_ROOM]: interaction.getRoom(),
      }
    );
  }

  trackRecordingStarted(
    recorder: IRecorderInfo,
    interaction: IInteraction
  ): void {
    if (!this.isValidInteraction(interaction)) {
      return;
    }

    this.pureCloudService.tagCustomer(
      interaction.getId(),
      interaction.getCustomerId(),
      {
        [TAG_CUSTOMER_RECORDING_INSTANCE_ID]: recorder.instanceId,
        [TAG_CUSTOMER_RECORDING_RECORDER_ID]: recorder.recorderId,
      }
    );
  }

  trackRecordingStopped(
    session: IRecorderSessionInfo,
    interaction: IInteraction
  ): void {
    /*
      @deprecated due to null session.url and session.provider. These can be found at the interactions page
      this.pureCloudService.tagCustomer(
      interaction.getId(),
      interaction.getCustomerId(),
      {
        [TAG_CUSTOMER_RECORDING_URL]: session.url,
        [TAG_CUSTOMER_RECORDING_STORAGE_PROVIDER]: session.provider,
      }
    );
    */
  }
}
