import {
  PARAM_APPLICATION_ID,
  PARAM_GUEST,
  PARAM_ROOM_ID,
} from "../../../app/app.enums";

import {
  IInteraction,
  ITicketRequest,
  TicketTypeEnum,
} from "../../../core-ui/models";
import { IInvitationStrategy } from "../../../core-ui/models/strategies";
import { ITicketAppointmentOptions } from "../../../core-ui/models/interfaces";
import { ScheduleTicketTypeEnum } from "../../../core-ui/models/ITicket";
import {
  IApplicationPublicParams,
  PublicParam,
} from "../../../core-ui/models/application-options/PublicOptions";
import { AuthState } from "../../../core-ui/models/AuthState";

export abstract class AbstractBaseInvitationStrategy
  implements IInvitationStrategy
{
  constructor(
    protected applicationId: string,
    protected publicParams: IApplicationPublicParams
  ) {}

  prepareScheduleTicketRequest(
    type: ScheduleTicketTypeEnum,
    length: number,
    conferenceId: string,
    customerId: string,
    interactionId: string,
    scheduledAt: string,
    customerName?: string,
    timezone?: string,
    queueId?: string
  ): ITicketRequest {
    return {
      type,
      length,
      properties: {
        application_id: this.applicationId,
        conference_id: conferenceId,
        customer_id: customerId,
        customer_name: customerName,
        ttl: 28800,
        scheduled_date: scheduledAt,
        join_base_url: `${window.location.origin}/t/`,
        theme: this.publicParams?.[PublicParam.THEME],
        interaction_id: interactionId,
        timezone,
        queueId,
      },
    };
  }

  prepareTicketRequest(
    type: TicketTypeEnum,
    length: number,
    conferenceId: string,
    customerId: string,
    interactionId: string,
    customerName?: string
  ): ITicketRequest {
    return {
      type,
      length,
      properties: {
        application_id: this.applicationId,
        conference_id: conferenceId,
        customer_id: customerId,
        customer_name: customerName,
        ttl: 14400,
        theme: this.publicParams?.[PublicParam.THEME],
        interaction_id: interactionId,
      },
    };
  }

  /**
   * @deprecated The backend will create the ticket
   */
  prepareAppointmentTicketRequest(
    agentUserId: string,
    options?: ITicketAppointmentOptions
  ): ITicketRequest {
    return {
      type: TicketTypeEnum.AppointmentScheduleTicket,
      properties: {
        application_id: this.applicationId,
        conference_id: null,
        customer_id: null,
        theme: this.publicParams?.[PublicParam.THEME],
        // agentId: agentUserId,
      },
    };
  }

  prepareAgentInvitation(interaction: IInteraction): string {
    const url = new URL("a", window.location.origin);

    url.searchParams.set(PARAM_ROOM_ID, interaction.getRoom());
    url.searchParams.set(PARAM_APPLICATION_ID, this.applicationId);
    url.searchParams.set(PARAM_GUEST, "true");

    const customParams = this.customParams(interaction);
    if (!!customParams) {
      customParams.forEach((value, key) => url.searchParams.append(key, value));
    }

    return url.toString();
  }

  prepareSupervisorInvitation(interaction: IInteraction): string {
    let queryParams = {};

    new URLSearchParams(window.location.search).forEach(
      (value, key) => (queryParams[key] = value)
    );

    const roomLink = new URL(this.prepareAgentInvitation(interaction));
    roomLink.searchParams.forEach((value, key) => (queryParams[key] = value));

    roomLink.search = new URLSearchParams({
      ...queryParams,
      state: AuthState.fromQueryParamMap(
        new URLSearchParams({
          ...queryParams,
          whisper: "true",
        })
      ).serialize(),
    }).toString();

    return roomLink.toString();
  }

  abstract customParams(interaction: IInteraction): URLSearchParams;
}
