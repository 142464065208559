import {
  Directive,
  OnDestroy,
  ElementRef,
  ViewContainerRef,
  Input,
} from "@angular/core";
import { SearchService } from "../../../core-ui/services/search.service";
import { AfterViewInit } from "@angular/core";
import { Util } from "@auvious/common";

@Directive({
  selector: "[avSearchable]",
})
export class SearchableDirective implements AfterViewInit, OnDestroy {
  @Input()
  set avSearchable(category: string) {
    this._category = category;
  }

  private _category: string;
  private _id: string;

  constructor(
    private search: SearchService,
    private host: ElementRef<HTMLDivElement>,
    private viewContainer: ViewContainerRef
  ) {
    this._id = Util.uuidgen();
  }

  ngAfterViewInit(): void {
    this.search.addSearchable({
      id: this._id,
      key: this._category,
      text: this.host.nativeElement.innerText,
      onMatch: (matches) => this.highlight(matches),
    });
  }

  ngOnDestroy(): void {
    this.search.removeSearchable(this._id);
  }

  private highlight(matches: string[]) {
    const elm = this.viewContainer.element.nativeElement;
    const walker = document.createTreeWalker(elm, NodeFilter.SHOW_TEXT);

    let focus: HTMLElement = null;

    while (walker.nextNode()) {
      const node = walker.currentNode;

      for (const match of matches) {
        let offset = 0;
        let index: number;

        while ((index = node.nodeValue.indexOf(match, offset)) > -1) {
          offset = index + match.length;

          const range = document.createRange();
          range.setStart(node, index);
          range.setEnd(node, offset);

          this.search.highlight.add(range);
        }

        if (offset)
          focus =
            focus === null || node.parentElement.offsetTop < focus.offsetTop
              ? node.parentElement
              : focus;
      }
    }

    focus?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}
