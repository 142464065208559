import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from "@angular/core";
import { IEndpoint, IStream } from "@auvious/rtc";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { IArea, IPoint } from "../../models";
import {
  ActivityIndicatorService,
  ArPointerActivatedEvent,
  ArPointerDeactivatedEvent,
  AuviousRtcService,
  debug,
  PointerService,
} from "../../services";
import { TileComponent } from "../tile/tile.component";

@Component({
  selector: "app-point-area",
  templateUrl: "./point-area.component.html",
  styleUrls: ["./point-area.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointAreaComponent implements OnInit, OnDestroy {
  @Input() stream: IStream;

  subscriptions: Subscription;
  points: IPoint[] = [];

  constructor(
    private pointerService: PointerService,
    private cdr: ChangeDetectorRef,
    private activityService: ActivityIndicatorService,
    private rtcService: AuviousRtcService,
    public host: ElementRef<HTMLDivElement>,
    @Optional() private parent?: TileComponent
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.pointerService.pointAvailable$
        .pipe(
          filter(
            (p) => p.endpoint === this.target && p.streamType === this.type
          )
        )
        .subscribe((point) => {
          debug("point-area:point available:", point);
          this.points = [...this.points, point];
          this.cdr.detectChanges();
        })
    );
    this.subscriptions.add(
      this.pointerService.pointAreaChange$
        .pipe(
          filter(
            (d) =>
              d.targetUserEndpointId === this.target &&
              d.targetStreamType === this.type
          )
        )
        .subscribe((data) => {
          debug("point-area:point area change:", data);
          switch (data.type) {
            case ArPointerDeactivatedEvent.type:
              this.points = [];
              this.parent.ArPointerOn = false;
              this.activityService.showIconMessage(
                "laser",
                "Laser pointer deactivated",
                1
              );
              break;
            case ArPointerActivatedEvent.type:
              this.parent.ArPointerOn = true;
              this.activityService.showIconMessage(
                "laser",
                "Laser pointer activated",
                1
              );
              break;
          }
          this.cdr.detectChanges();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @HostBinding("class") get class() {
    return {
      "point-area-active": this.active,
      "point-area-sender": this.isSender,
    };
  }

  @HostListener("click", ["$event"])
  onClick(e) {
    if (!this.parent?.isWhisper) {
      this.point(e);
    }
  }

  trackByFn(index: number, el: IPoint) {
    return el.id;
  }

  point(e: MouseEvent) {
    if (this.active && this.isSender) {
      if (
        // @ts-expect-error
        e.target.classList &&
        // @ts-expect-error
        e.target.classList.contains("point-area-active")
      ) {
        this.pointerService.point(e, this.target, this.type);
      }
    }
  }

  pointerDestroyed(point: IPoint) {
    this.points = this.points.filter((p) => p?.id !== point.id);
  }

  get area(): IArea {
    return {
      width: this.parent?.container.clientWidth,
      height: this.parent?.container.clientHeight,
    };
  }

  get target() {
    return this.stream.originator.endpoint;
  }

  get type() {
    return this.stream.type;
  }

  get active() {
    return this.pointerService.isTarget(this.target, this.stream.type);
  }
  get isSender() {
    return !this.parent?.isWhisper; // this.pointerService.isRequester(this.myself.endpoint);
  }
}
