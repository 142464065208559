import { Injectable } from "@angular/core";
import {
  IEndpoint,
  IPublishedStream,
  IStream,
  StreamType,
  StreamTypes,
} from "@auvious/rtc";
import { merge, ReplaySubject } from "rxjs";
import { VideoFacingModeEnum, LayoutEnum } from "../../core-ui.enums";

import {
  EndpointStateEnum,
  IStreamMetadata,
  IStreamMetadataChangedEvent,
} from "../../models";
import { ConferenceService } from "../conference.service";
import { DeviceService } from "../device.service";
import { LocalMediaService } from "../local.media.service";
import { PointerService } from "../pointer.service";
import { AuviousRtcService } from "../rtc.service";
import { SnapshotService } from "../snapshot.service";
import { debugError } from "../utils";
import { VoiceDetectionService } from "../voice-detection.service";
import { IBandwidthViewerChangedEvent } from "../bandwidth.adaptation.service";
import {
  BandwidthAdaptationService,
  IBandwidthPublisherBitrateChangedEvent,
} from "../bandwidth.adaptation.service";

export type TrackState =
  | "enabled"
  | "muted"
  | "muting"
  | "unmuting"
  | "disabled";

export interface StreamState {
  id: string;
  correlationId: string;
  type: StreamType;
  originator: IEndpoint;
  published: boolean;
  primary: boolean;
  video: {
    state: TrackState;
    facingMode: VideoFacingModeEnum;
    portraitMode: boolean;
    background: boolean;
    snapshot: boolean;
    sketch: boolean;
    freeze: boolean;
    /** the surface a local screen share displays */
    display: MediaDisplaySurface;
    disabledFromBitrateAdaptation: boolean;
    deviceId: string;
  };
  audio: {
    state: TrackState;
    vad: boolean;
    asr: boolean;
    deviceId: string;
  };
}

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? DeepPartial<U>[]
    : // eslint-disable-next-line
    T[P] extends object
    ? DeepPartial<T[P]>
    : T[P];
};

export interface EndpointState {
  id: string;
  username: string;
  state: "joining" | "joined" | "left";
  connectivity: "stable" | "unstable";
  streams: string[];
  bitrate?: number;
}

export interface LayoutState {
  type?: LayoutEnum;
}

@Injectable()
export class ConferenceStore {
  public endpoint: string = null;
  public username: string = null;
  public myself: EndpointState = null;
  public mystream: StreamState = null;
  public mySecondaryStream: StreamState = null;

  public streams: StreamState[] = [];
  public endpoints: EndpointState[] = [];
  public screenStream: StreamState = null;
  public snapshotStream: StreamState = null;

  public layout: LayoutState = null;

  private readonly _updated = new ReplaySubject<void>(1);
  public readonly updated$ = this._updated.asObservable();

  constructor(
    private conference: ConferenceService,
    private rtc: AuviousRtcService,
    private vad: VoiceDetectionService,
    private local: LocalMediaService,
    private snapshots: SnapshotService,
    private pointerService: PointerService,
    private device: DeviceService,
    private badwidthAdaptation: BandwidthAdaptationService
  ) {
    // @ts-expect-error
    window.cstate = this;

    this.emit();

    this.rtc.rtc$.subscribe((client) => {
      this.registered(client.identity(), client.endpoint());
    });

    this.rtc.connectionRegistered$.subscribe((me) => {
      this.registered(me.username, me.endpoint);
    });

    this.conference.endpointJoined$.subscribe((originator) => {
      this.endpointJoined(originator);
    });

    this.conference.endpointStateChanged$.subscribe(({ endpoint, state }) => {
      this.endpointStateChanged(endpoint, state);
    });

    this.conference.endpointLeft$.subscribe(({ endpoint }) => {
      this.endpointLeft(endpoint);
    });

    this.local.localStreamReady$.subscribe((stream) => {
      this.localStreamAdded(stream);
    });

    this.conference.facingModeChanged$.subscribe(({ streamId, facingMode }) => {
      this.updateFacingMode(streamId, facingMode);
    });

    this.local.localStreamReplaced$.subscribe((event) => {
      this.localStreamReplaced(event.previous, event.next);
    });

    this.conference.streamAdded$.subscribe((stream) => {
      if (stream.originator.endpoint !== this.myself.id) {
        this.remoteStreamAdded(stream);
      }
    });

    this.conference.localStreamPublished$.subscribe((stream) => {
      this.localStreamPublished(stream);
    });

    this.conference.streamMetadataChanged$.subscribe((change) => {
      this.metadataUpdated(change);
    });

    merge(
      this.conference.streamMutedChange$,
      this.local.streamMutedChange$
    ).subscribe((change) => {
      this.trackStateUpdated(
        change.stream?.id,
        change.trackKind,
        change.muted ? "muted" : "enabled"
      );
    });

    this.local.streamMuteWillChange$.subscribe((change) => {
      this.trackStateUpdated(
        change.stream.id,
        change.trackKind,
        change.mute ? "muting" : "unmuting"
      );
    });

    this.vad.vadStreamState$.subscribe((state) => {
      this.vadStateUpdated(state.id, state.enabled);
    });

    merge(this.local.streamRemoved$, this.conference.streamRemoved$).subscribe(
      (stream) => {
        this.streamRemoved(stream);
      }
    );

    this.snapshots.requested$.subscribe((stream) => {
      this.snapshotRequested(stream);
    });

    this.pointerService.freezeFrameChange$.subscribe((e) => {
      this.frameFreezeChanged(e.target.endpoint, e.type, e.on);
    });

    this.snapshots.ended$.subscribe(() => {
      this.snapshotEnded();
    });

    merge(
      this.badwidthAdaptation.bitrateIncreased$,
      this.badwidthAdaptation.bitrateDecreased$
    ).subscribe((changedEvent) => this.bitrateChanged(changedEvent));

    this.badwidthAdaptation.viewerDisabled$.subscribe((changedEvent) => {
      this.bitrateToggleDisabledStream(changedEvent, true);
    });

    this.badwidthAdaptation.viewerEnabled$.subscribe((changedEvent) => {
      this.bitrateToggleDisabledStream(changedEvent, false);
    });
  }

  // - names of public methods suggest reaction to events

  public registered(username: string, endpoint: string) {
    this.endpoint = endpoint;
    this.username = username;

    this.upsertEndpoint({
      id: endpoint,
      username,
      state: "joining",
      connectivity: "stable",
      streams: [],
    });

    this.emit();
  }

  public endpointJoined(originator: IEndpoint) {
    this.upsertEndpoint(
      {
        id: originator.endpoint,
        username: originator.username,
        state: "joined",
        connectivity: "stable",
        streams: [],
      },
      { state: "joined", connectivity: "stable" }
    );

    this.emit();
  }

  public endpointStateChanged(endpoint: string, state: EndpointStateEnum) {
    if (
      this.upsertEndpoint(null, {
        id: endpoint,
        connectivity: state === EndpointStateEnum.Sick ? "unstable" : "stable",
        state: state === EndpointStateEnum.Left ? "left" : "joined",
      })
    ) {
      this.emit();
    }
  }

  public endpointLeft(endpoint: string) {
    this.streams = this.streams.filter(
      ({ originator }) => originator.endpoint !== endpoint
    );

    if (this.endpoint !== endpoint) {
      this.endpoints = this.endpoints.filter(({ id }) => id !== endpoint);
    }

    this.emit();
  }

  public localStreamAdded(stream: IStream) {
    // only one screen stream per session
    if (this.isScreenShareIgnored(stream)) {
      return;
    }

    if (stream.getMetadata()?.correlationId) {
      this.streams = this.streams.filter(
        (s) => s.correlationId !== stream.getMetadata().correlationId
      );
    } else {
      // remove my stream from the streams array so that we can add it again in upsert
      this.streams = this.streams.filter(
        (s) =>
          // my stream is based on the type. I cannot have the same type twice (?)
          s.type !== stream.type ||
          // keep the non-screen streams that are not mine
          (stream.type !== StreamTypes.SCREEN &&
            s.originator.endpoint !== this.endpoint)
      );
    }

    this.upsertStream({
      id: stream.id,
      correlationId: (stream.getMetadata() as IStreamMetadata).correlationId,
      type: stream.type,
      originator: stream.originator,
      published: false,
      primary: (stream.getMetadata() as IStreamMetadata).primary,
      audio: {
        state: this.getTrackState(
          stream.type,
          stream.mediaStream?.getAudioTracks()[0]
        ),
        vad: this.vad.isEnabled(stream),
        asr: false,
        deviceId: (stream.getMetadata() as IStreamMetadata)?.audio?.settings
          ?.deviceId,
      },
      video: {
        state: this.getTrackState(
          stream.type,
          stream.mediaStream?.getVideoTracks()[0]
        ),
        facingMode: this.local.getFacingModeForStream(stream),
        portraitMode: stream.getMetadata()?.portraitMode === true,
        background: false,
        snapshot: false,
        sketch: false,
        freeze: false,
        disabledFromBitrateAdaptation: false,
        display:
          stream.type === StreamTypes.SCREEN
            ? this.device.getMediaDisplaySurface(stream as IPublishedStream)
            : "unknown",
        deviceId: (stream.getMetadata() as IStreamMetadata)?.video?.settings
          ?.deviceId,
      },
    });

    this.myself.streams = this.streams
      .filter((s) => s.originator.endpoint === this.endpoint)
      .map((s) => s.id);

    this.emit();
  }

  public localStreamReplaced(
    previous: IPublishedStream,
    next: IPublishedStream
  ) {
    let updated = false;

    if (!previous) {
      this.localStreamAdded(next);
    } else {
      updated = !!this.upsertStream(this.getStream(previous.id), {
        id: next.id,
      });
    }

    updated ||= !!this.upsertEndpoint(null, {
      id: this.endpoint,
      streams: this.streams
        .filter((s) => s.originator.endpoint === this.endpoint)
        .map((s) => s.id),
    });

    if (updated) {
      this.emit();
    }
  }

  public localStreamPublished(stream: IPublishedStream) {
    if (!stream) {
      return;
    }

    const updated = !!this.upsertStream(null, {
      id: stream.id,
      published: true,
    });

    if (updated) {
      this.emit();
    }
  }

  public remoteStreamAdded(stream: IStream) {
    // only one screen stream per session
    if (this.isScreenShareIgnored(stream)) {
      return;
    }

    const endpoint = this.getEndpoint(stream.originator.endpoint);

    if (!endpoint) {
      // should not really happen
      debugError(new Error("Cannot add streams of absent participant."));
      return;
    }

    if (stream.getMetadata()?.correlationId) {
      this.streams = this.streams.filter(
        (s) => s.correlationId !== stream.getMetadata().correlationId
      );
    } else {
      this.streams = this.streams.filter(
        (s) =>
          s.type !== stream.type ||
          (stream.type !== StreamTypes.SCREEN &&
            s.originator.endpoint !== stream.originator.endpoint)
      );
    }

    this.upsertStream({
      id: stream.id,
      correlationId: (stream.getMetadata() as IStreamMetadata)?.correlationId,
      type: stream.type,
      originator: stream.originator,
      published: endpoint.state === "joined",
      primary: (stream.getMetadata() as IStreamMetadata)?.primary ?? true,
      audio: {
        state: this.getTrackState(
          stream.type,
          stream.mediaStream?.getAudioTracks()[0]
        ),
        vad: this.vad.isEnabled(stream),
        asr: false,
        deviceId: (stream.getMetadata() as IStreamMetadata)?.audio?.settings
          ?.deviceId,
      },
      video: {
        state: this.getTrackState(
          stream.type,
          stream.mediaStream?.getVideoTracks()[0]
        ),
        facingMode: this.conference.getFacingModeForStream(stream),
        // TOOD: why does remote stream not have getMetadata
        portraitMode: stream?.getMetadata()?.portraitMode === true,
        background: false,
        snapshot: false,
        sketch: false,
        freeze: false,
        disabledFromBitrateAdaptation: false,
        display: "unknown",
        deviceId: (stream.getMetadata() as IStreamMetadata)?.video?.settings
          ?.deviceId,
      },
    });

    endpoint.streams = this.streams
      .filter((s) => s.originator.endpoint === endpoint.id)
      .map((s) => s.id);

    this.emit();
  }

  public metadataUpdated(update: IStreamMetadataChangedEvent) {
    if (
      this.upsertStream(null, {
        id: update.streamId,
        video: {
          portraitMode: update.newMetadata.portraitMode,
          deviceId: update.newMetadata.video?.settings.deviceId,
        },
        audio: {
          deviceId: update.newMetadata.audio?.settings.deviceId,
        },
      })
    ) {
      this.emit();
    }
  }

  public updateFacingMode(id: string, facingMode: VideoFacingModeEnum) {
    if (
      this.getStream(id)?.video.facingMode !== facingMode &&
      this.upsertStream(null, {
        id,
        video: {
          facingMode,
        },
      })
    ) {
      this.emit();
    }
  }

  public trackStateUpdated(
    id: string,
    track: "audio" | "video",
    state: TrackState
  ) {
    if (
      id &&
      this.upsertStream(null, {
        id,
        [track]: {
          state,
        },
      })
    ) {
      this.emit();
    }
  }

  public vadStateUpdated(id: string, enabled: boolean) {
    if (
      this.upsertStream(null, {
        id,
        audio: {
          vad: enabled,
        },
      })
    ) {
      this.emit();
    }
  }

  public streamRemoved(stream: IStream) {
    if (!this.getStream(stream.id)) {
      if (stream.id === this.screenStream?.id) {
        this.screenStream = null;
      }
      return;
    }

    const endpoint = this.getEndpoint(stream.originator.endpoint);

    if (endpoint) {
      endpoint.streams = endpoint.streams.filter((id) => id !== stream.id);
    }

    if (this.mystream?.id === stream.id) {
      this.mystream = null;
    } else if (this.screenStream?.id === stream.id) {
      this.screenStream = null;
    } else if (this.mySecondaryStream?.id === stream.id) {
      this.mySecondaryStream = null;
    }

    if (this.snapshotStream?.id === stream.id) {
      this.snapshotStream = null;
    }

    this.streams = this.streams.filter(({ id }) => id !== stream.id);

    this.emit();
  }

  public snapshotRequested({ id }: IStream) {
    const update = this.upsertStream(null, { id, video: { snapshot: true } });

    if (update) {
      this.snapshotStream = update;
      this.emit();
    }
  }

  public frameFreezeChanged(endpoint: string, type: StreamType, on: boolean) {
    const stream = this.streams.find(
      (s) => s.originator.endpoint === endpoint && s.type === type
    );

    if (
      stream &&
      this.upsertStream(null, {
        id: stream.id,
        video: { freeze: on },
      })
    ) {
      this.emit();
    }
  }

  public snapshotEnded() {
    if (this.snapshotStream) {
      this.upsertStream(null, {
        id: this.snapshotStream.id,
        video: { snapshot: false },
      });

      this.snapshotStream = null;

      this.emit();
    }
  }

  public layoutChanged(type: LayoutEnum) {
    this.layout = {
      ...this.layout,
      type,
    };
    this.emit();
  }

  public getEndpoint(id: string) {
    return this.endpoints.find((e) => e.id === id) || null;
  }

  public getStream(id: string) {
    return this.streams.find((s) => s.id === id) || null;
  }

  private mergeStream(stream: StreamState, diff: DeepPartial<StreamState>) {
    if (diff.originator) {
      diff.originator = { ...stream.originator, ...diff.originator };
    }

    if (diff.audio) {
      diff.audio = { ...stream.audio, ...diff.audio };
    }

    if (diff.video) {
      diff.video = { ...stream.video, ...diff.video };
    }

    return { ...stream, ...diff } as StreamState;
  }

  /** if id exists, merge if given, else insert */
  private upsertStream(
    insert?: StreamState,
    orMerge?: DeepPartial<StreamState>
  ) {
    const id = insert ? insert.id : orMerge?.id;

    let update: StreamState;
    let found: StreamState;

    const streams = this.streams
      .filter((s) => {
        if (s.id === id) {
          found = s;
        } else {
          return true;
        }
      })
      // if we want to merge and the stream already exists, merge, otherwise insert, or else return the same one
      .concat(
        (update =
          (orMerge && found ? this.mergeStream(found, orMerge) : insert) ||
          found)
      );

    // some events arrive earlier, their information will be queried directly from those services later
    if (update === found) {
      return null;
    } else {
      this.streams = streams;
    }

    if (update.type === StreamTypes.SCREEN) {
      this.screenStream = update;
    } else if (update?.originator.endpoint === this.endpoint) {
      if (update.primary) {
        this.mystream = update;
      } else {
        this.mySecondaryStream = update;
      }
    }

    if (id === this.snapshotStream?.id) {
      this.snapshotStream = update;
    }

    return update;
  }

  /** if id exists, merge if given, else insert */
  private upsertEndpoint(
    insert?: EndpointState,
    orMerge?: Partial<EndpointState>
  ) {
    const id = insert ? insert.id : orMerge?.id;

    let update: EndpointState;
    let found: EndpointState;

    const endpoints = this.endpoints
      .filter((e) => {
        if (e.id === id) {
          found = e;
        } else {
          return true;
        }
      })
      .concat(
        (update =
          (orMerge && found ? { ...found, ...orMerge } : insert) || found)
      );

    if (update === found) {
      return null;
    } else {
      this.endpoints = endpoints;
    }

    if (update?.id === this.endpoint) {
      this.myself = update;
    }

    return update;
  }

  private getTrackState(type: StreamType, track: MediaStreamTrack): TrackState {
    if (type === StreamTypes.SCREEN) {
      return !track
        ? "disabled"
        : track.readyState === "ended" || !track.enabled
        ? "muted"
        : "enabled";
    } else {
      return !track || track.readyState === "ended" || !track.enabled
        ? "muted"
        : "enabled";
    }
  }

  private isScreenShareIgnored(stream: IStream) {
    return (
      stream.type === StreamTypes.SCREEN &&
      this.screenStream &&
      this.conference.resolveScreenShareConflict(
        this.conference.screenShareStream || this.local.screenStream.stream,
        stream as IPublishedStream
      ).id === this.screenStream.id
    );
  }

  private bitrateChanged(event: IBandwidthPublisherBitrateChangedEvent) {
    this.upsertEndpoint(null, {
      id: event.userEndpointId,
      bitrate: event.newBitrate,
    });
    this.emit();
  }

  private bitrateToggleDisabledStream(
    changedEvent: IBandwidthViewerChangedEvent,
    disabled: boolean
  ) {
    if (
      this.upsertStream(null, {
        id: changedEvent.streamId,
        video: {
          disabledFromBitrateAdaptation: disabled,
        },
      })
    ) {
      this.emit();
    }
  }

  private emit() {
    this._updated.next();
  }
}
