import { Injectable } from "@angular/core";
import { IEndpoint, StreamTypes } from "@auvious/rtc";
import { Subject, firstValueFrom, map, filter } from "rxjs";
import {
  ConversationOriginEnum,
  RoomModeratorEnum,
  SQ_VIDEO_BITRATE_INDEX,
  StreamTrackKindEnum,
  UserRoleEnum,
  VideoFacingModeEnum,
  VIDEO_BITRATE_OPTIONS,
  OriginModeEnum,
  NotificationSoundEnum,
  EndpointTypeEnum,
  ConversationTypeEnum,
  ICallQualityOptions,
  VIDEO_BITRATE_MAP,
} from "../core-ui.enums";
import { AgentParam, CustomerParam, IEndpointMetadata } from "../models";
import { PublicParam } from "../models/application-options/PublicOptions";
import { IInteraction } from "../models/IInteraction";
import { ConfirmNotification } from "../models/notifications/ConfirmNotification";
import { AppConfigService, FEATURES } from "./app.config.service";
import { ConferenceService } from "./conference.service";
import { DeviceService } from "./device.service";
import { NotificationService } from "./notification.service";
import { PointerService } from "./pointer.service";
import { AuviousRtcService } from "./rtc.service";
import { UserService } from "./user.service";
import { CobrowseService } from "./cobrowse.service";
import { BaseEvent } from "../models/IEvent";
import { LocalMediaService } from "./local.media.service";
import { IDevice } from "@auvious/media-tools";

export class StreamStartRequestEvent extends BaseEvent {
  public static type = "StreamStartRequestEvent";
  constructor(
    public originatorUsername: string,
    public originatorEndpoint: string,
    public deviceId: string
  ) {
    super("StreamStartRequestEvent");
  }
}

export class StreamStartRejectEvent extends BaseEvent {
  public static type = "StreamStartRejectEvent";
  constructor(
    public originatorUsername: string,
    public originatorEndpoint: string,
    public error: boolean
  ) {
    super("StreamStartRejectEvent");
  }
}

export class StreamStopRequestEvent extends BaseEvent {
  public static type = "StreamStopRequestEvent";
  constructor(
    public originatorUsername: string,
    public originatorEndpoint: string,
    public correlationId: string
  ) {
    super("StreamStopRequestEvent");
  }
}

export class DesktopCaptureRequestEvent extends BaseEvent {
  public static type = "DesktopCaptureRequestEvent";
  constructor(
    public originatorUsername: string,
    public originatorEndpoint: string
  ) {
    super("DesktopCaptureRequestEvent");
  }
}

export class DesktopCaptureRejectEvent extends BaseEvent {
  public static type = "DesktopCaptureRejectEvent";
  constructor(
    public originatorUsername: string,
    public originatorEndpoint: string,
    public error: boolean
  ) {
    super("DesktopCaptureRejectEvent");
  }
}

export class DesktopCaptureTerminateEvent extends BaseEvent {
  public static type = "DesktopCaptureTerminateEvent";
  constructor(
    public originatorUsername: string,
    public originatorEndpoint: string
  ) {
    super("DesktopCaptureTerminateEvent");
  }
}

@Injectable()
export class MediaRulesService {
  private pendingConfirmations: Map<string, ConfirmNotification> = new Map();
  private streamQualityMap: Map<string, number> = new Map();

  private _pictureInPictureChanged = new Subject<{
    streamId: string;
    on: boolean;
  }>();
  public pictureInPictureChanged$ =
    this._pictureInPictureChanged.asObservable();

  private _streamQualityChanged = new Subject<{
    streamId: string;
    bitrate: number;
  }>();
  public streamQualityChanged$ = this._streamQualityChanged.asObservable();

  private _desktopCaptureRejected = new Subject<{ error: boolean }>();
  public desktopCaptureRejected$ = this._desktopCaptureRejected.asObservable();

  private _secondaryStreamRejected = new Subject<{
    sender: IEndpoint;
    error: Error;
  }>();
  public secondaryStreamRejected$ =
    this._secondaryStreamRejected.asObservable();

  private _callQualityOptions: ICallQualityOptions;

  constructor(
    private conference: ConferenceService,
    private notification: NotificationService,
    private pointer: PointerService,
    private rtc: AuviousRtcService,
    private user: UserService,
    private config: AppConfigService,
    private device: DeviceService,
    private cobrowse: CobrowseService,
    private local: LocalMediaService
  ) {
    firstValueFrom(rtc.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable
          .pipe(
            filter(
              (data) => data?.payload?.type === DesktopCaptureRequestEvent.type
            )
          )
          .subscribe((event) => {
            // customer gets the event
            const { originatorUsername, originatorEndpoint } = event.payload;
            this.displayCaptureRequest({
              username: originatorUsername,
              endpoint: originatorEndpoint,
            });
          });

        eventObservable
          .pipe(
            filter(
              (data) => data?.payload?.type === StreamStartRequestEvent.type
            )
          )
          .subscribe(async (event) => {
            // customer gets the event
            const { deviceId, originatorUsername, originatorEndpoint } =
              event.payload;
            try {
              await this.local.openSecondaryCameraStream(deviceId);
            } catch (ex) {
              this.rejectRemoteStreamStart(
                { username: originatorUsername, endpoint: originatorEndpoint },
                ex
              );
            }
          });

        eventObservable
          .pipe(
            filter(
              (data) => data?.payload?.type === StreamStartRejectEvent.type
            )
          )
          .subscribe(async (event) => {
            // agent gets the event
            this._secondaryStreamRejected.next({
              sender: {
                username: event.payload.originatorUsername,
                endpoint: event.payload.originatorEndpoint,
              },
              error: event.payload.error,
            });
          });

        eventObservable
          .pipe(
            filter(
              (data) => data?.payload?.type === StreamStopRequestEvent.type
            )
          )
          .subscribe((event) => {
            // customer gets the event
            const { correlationId } = event.payload;
            this.local.closeSecondaryCameraStream(correlationId);
          });

        eventObservable
          .pipe(
            filter(
              (data) => data?.payload?.type === DesktopCaptureRejectEvent.type
            )
          )
          .subscribe((event) => {
            // agent gets the event
            const { error } = event.payload;
            this._desktopCaptureRejected.next(error);
            if (error) {
              this.notification.info("Desktop capture failed", {
                body: "The customer encountered an error when attempting to share their screen.",
              });
            } else {
              this.notification.info("Desktop capture rejected", {
                body: "The customer declined to share their screen.",
              });
            }
          });

        eventObservable
          .pipe(
            filter(
              (data) =>
                data?.payload?.type === DesktopCaptureTerminateEvent.type
            )
          )
          .subscribe((event) => {
            // customer gets the event
            this.local.closeScreenStream();
          });
      }
    );

    this.conference.endpointJoined$
      .pipe(
        filter(
          (originator) =>
            config.publicParam(
              PublicParam.SOUND_NOTIFICATIONS_PARTICIPANT_ENABLED
            ) &&
            !!originator &&
            originator.username !== this.rtc.myself.username &&
            originator.metadata?.type !== EndpointTypeEnum.coBrowse &&
            // do not play a sound for me (supervisor) if others joined
            !this.conference.getMyParticipantMetadata()?.whisperMode &&
            // do not play a sound for participants if supervisor joined
            !(
              (originator.metadata as IEndpointMetadata)?.roles.includes(
                UserRoleEnum.supervisor
              ) && !!(originator.metadata as IEndpointMetadata)?.whisperMode
            )
        )
      )
      .subscribe((o) => {
        this.notification.playSound(NotificationSoundEnum.participantJoin);
      });

    this.conference.participantLeft$
      .pipe(
        filter(
          (originator) =>
            config.publicParam(
              PublicParam.SOUND_NOTIFICATIONS_PARTICIPANT_ENABLED
            ) &&
            !!originator &&
            originator.username !== this.rtc.myself.username &&
            !this.conference.getMyParticipantMetadata()?.whisperMode &&
            !(
              (originator.metadata as IEndpointMetadata)?.roles.includes(
                UserRoleEnum.supervisor
              ) && !!(originator.metadata as IEndpointMetadata)?.whisperMode
            )
        )
      )
      .subscribe((s) => {
        this.notification.playSound(NotificationSoundEnum.participantLeave);
      });

    this.conference.streamAdded$
      .pipe(
        filter(
          (stream) =>
            // enabled in settings
            config.agentParamEnabled(AgentParam.AUTO_START_AR) &&
            // not started for another stream
            !this.pointer.isStarted &&
            // is customer
            stream.originator.metadata?.roles?.includes(
              UserRoleEnum.customer
            ) &&
            // not already asked for confirm (in case of browse refresh)
            !this.pendingConfirmations.has(stream.originator.username) &&
            // is video
            [StreamTypes.VIDEO, StreamTypes.CAM].includes(stream.type) &&
            // not muted
            !stream.isMuted("video") &&
            // not on co-browse
            !this.cobrowse.isStarted &&
            !this.cobrowse.isPendingApproval &&
            // not screen-sharing
            !this.conference.screenShareStream
        )
      )
      .subscribe((stream) => {
        const cnotif = new ConfirmNotification(
          "New customer joined",
          "Would you like to start AR pointer?"
        );
        this.pendingConfirmations.set(stream.originator.username, cnotif);
        cnotif.onConfirmed(() => {
          this.pointer.togglePointArea(
            stream.originator.endpoint,
            stream.type,
            this.rtc.endpoint()
          );
          this.removePendingConfirmation(stream.originator);
        });
        cnotif.onCanceled(() => {
          this.removePendingConfirmation(stream.originator);
        });
        this.notification.notify(cnotif);
      });

    this.conference.participantLeft$
      .pipe(filter((p) => this.pendingConfirmations.has(p?.username)))
      .subscribe((participant) => {
        this.notification.dismiss(
          this.pendingConfirmations.get(participant.username)
        );
        this.removePendingConfirmation(participant);
      });

    this.conference.localStreamPublished$
      .pipe(
        filter(
          (s) =>
            !!s &&
            (s.type === StreamTypes.SCREEN ||
              ([StreamTypes.SCREEN, StreamTypes.VIDEO].includes(s.type) &&
                !this.conference.isMutedStream(s, StreamTrackKindEnum.video)))
        )
      )
      .subscribe(async (stream) => {
        let bitrateOption: number;
        const callQuality = this.local.getCallQualityOptions();
        switch (stream.type) {
          case StreamTypes.SCREEN:
            bitrateOption =
              VIDEO_BITRATE_MAP[callQuality?.bitrate?.displayCapture] ||
              this.config.publicParam(PublicParam.VIDEO_SCREEN_SHARE_QUALITY);
            break;
          case StreamTypes.CAM:
          case StreamTypes.VIDEO:
            switch (this.conference.getFacingModeForStream(stream)) {
              case VideoFacingModeEnum.Environment:
                bitrateOption =
                  VIDEO_BITRATE_MAP[callQuality?.bitrate?.environment] ||
                  this.config.publicParam(
                    PublicParam.VIDEO_ENVIRONMENT_QUALITY
                  );
                break;
              case VideoFacingModeEnum.User:
              default:
                bitrateOption =
                  VIDEO_BITRATE_MAP[callQuality?.bitrate?.user] ||
                  this.config.publicParam(PublicParam.VIDEO_SELFIE_QUALITY);
                break;
            }
            break;
        }

        const activeQuality = this.getQualityForStream(stream.id);
        if (bitrateOption !== activeQuality) {
          await stream.updateVideoBitrate(bitrateOption);
          this.setStreamQuality(stream.id, bitrateOption);
        }
      });
  }

  public dismissPendingConfirmations() {
    this.pendingConfirmations.forEach((notification) => {
      this.notification.dismiss(notification);
    });
    this.pendingConfirmations.clear();
  }

  private displayCaptureRequest(sender: IEndpoint) {
    const n = new ConfirmNotification(
      "Desktop Capture request",
      "Agent is requesting access to view your screen or a window."
    );
    n.onConfirmed(async () => {
      const response = await this.local.shareScreen();
      if (response.success) {
        return;
      }
      if (response.reject) {
        this.displayCaptureReject(sender, false);
      } else if (response.error) {
        this.displayCaptureReject(sender, true);
      }
    });
    n.onCanceled(() => {
      this.displayCaptureReject(sender, false);
    });
    this.notification.notify(n);
  }

  private displayCaptureReject(sender: IEndpoint, error: boolean) {
    this.rtc.sendEventMessage(
      sender.username,
      sender.endpoint,
      new DesktopCaptureRejectEvent(
        this.rtc.myself.username,
        this.rtc.myself.endpoint,
        error
      )
    );
  }

  private removePendingConfirmation(participant: IEndpoint) {
    this.pendingConfirmations.delete(participant.username);
  }

  public setPictureInPicture(streamId: string, on: boolean) {
    this._pictureInPictureChanged.next({ streamId, on });
  }

  public setStreamQuality(streamId: string, bitrate: number) {
    this.streamQualityMap.set(streamId, bitrate);
    this._streamQualityChanged.next({ streamId, bitrate });
  }

  public getQualityForStream(streamId: string): number {
    const defaultQuality = VIDEO_BITRATE_OPTIONS[SQ_VIDEO_BITRATE_INDEX].value;
    return this.streamQualityMap.get(streamId) || defaultQuality;
  }

  public requestRemoteStreamStart(target: IEndpoint, device: IDevice) {
    this.rtc.sendEventMessage(
      target.username,
      target.endpoint,
      new StreamStartRequestEvent(
        this.rtc.myself.username,
        this.rtc.myself.endpoint,
        device.deviceId
      )
    );
  }

  public rejectRemoteStreamStart(originator: IEndpoint, error: Error) {
    this.rtc.sendEventMessage(
      originator.username,
      originator.endpoint,
      new StreamStartRejectEvent(
        this.rtc.myself.username,
        this.rtc.myself.endpoint,
        !!error
      )
    );
  }

  public requestRemoteStreamStop(target: IEndpoint, correlationId: string) {
    this.rtc.sendEventMessage(
      target.username,
      target.endpoint,
      new StreamStopRequestEvent(
        this.rtc.myself.username,
        this.rtc.myself.endpoint,
        correlationId
      )
    );
  }

  public requestRemoteDesktopCapture(target: IEndpoint) {
    this.notification.info("Desktop Capture request", {
      body: "The participant has been sent a request to share their desktop or a window.",
    });

    this.rtc.sendEventMessage(
      target.username,
      target.endpoint,
      new DesktopCaptureRequestEvent(
        this.rtc.myself.username,
        this.rtc.myself.endpoint
      )
    );
  }

  public terminateRemoteDesktopCapture(target: IEndpoint) {
    // this.notification.info("Desktop capture terminate request", {
    //   body: "The participant has been sent a request to stop sharing.",
    // });
    this.rtc.sendEventMessage(
      target.username,
      target.endpoint,
      new DesktopCaptureTerminateEvent(
        this.rtc.myself.username,
        this.rtc.myself.endpoint
      )
    );
  }

  public get isSpeakerAvailable() {
    return (
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.STREAM_CONTROLS_ENABLED)) ||
      (this.user.isCustomer &&
        this.config.customerParamEnabled(CustomerParam.SPEAKER_ENABLED))
    );
  }

  public get isAudioAvailable() {
    return (
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.STREAM_CONTROLS_ENABLED) &&
        this.config.agentParamEnabled(AgentParam.MICROPHONE_ENABLED)) ||
      (this.user.isCustomer &&
        this.config.customerParamEnabled(CustomerParam.MICROPHONE_ENABLED))
    );
  }

  public get isAudioControlAvailable() {
    return (
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.STREAM_CONTROLS_ENABLED) &&
        this.config.agentParamEnabled(AgentParam.MICROPHONE_ENABLED)) ||
      (this.user.isCustomer &&
        this.config.customerParamEnabled(CustomerParam.MICROPHONE_ENABLED) &&
        this.config.customerParamEnabled(CustomerParam.MEDIA_CONTROLS_ENABLED))
    );
  }

  public get joinWithAudioOn() {
    return (
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.MICROPHONE_DEFAULT_ON)) ||
      (this.user.isCustomer &&
        this.config.customerParamEnabled(CustomerParam.MICROPHONE_DEFAULT_ON))
    );
  }

  public get isVideoAvailable() {
    return (
      (this.user.isCustomer &&
        this.config.customerParamEnabled(CustomerParam.CAMERA_ENABLED)) ||
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.STREAM_CONTROLS_ENABLED) &&
        this.config.agentParamEnabled(AgentParam.CAMERA_ENABLED))
    );
  }

  public get isVideoControlAvailable() {
    return (
      (this.user.isCustomer &&
        this.config.customerParamEnabled(
          CustomerParam.MEDIA_CONTROLS_ENABLED
        ) &&
        this.config.customerParamEnabled(CustomerParam.CAMERA_ENABLED)) ||
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.STREAM_CONTROLS_ENABLED) &&
        this.config.agentParamEnabled(AgentParam.CAMERA_ENABLED))
    );
  }

  public get joinWithVideoOn() {
    return (
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.CAMERA_DEFAULT_ON)) ||
      (this.user.isCustomer &&
        this.config.customerParamEnabled(CustomerParam.CAMERA_DEFAULT_ON))
    );
  }

  public get isDisplayCaptureAvailable() {
    // if we don't have a value to the config, fall back to config.js
    const agentSS =
      this.user.isAgent &&
      (this.config.agentParam(AgentParam.SCREEN_SHARE_ENABLED) == null
        ? !this.config.featureEnabled([FEATURES.SCREEN_SHARE_CUSTOMER_ONLY])
        : this.config.agentParamEnabled(AgentParam.SCREEN_SHARE_ENABLED));

    const customerSS =
      this.user.isCustomer &&
      (this.config.customerParam(CustomerParam.SCREEN_SHARE_ENABLED) == null
        ? true
        : this.config.customerParamEnabled(
            CustomerParam.SCREEN_SHARE_ENABLED
          ) &&
          this.config.customerParamEnabled(
            CustomerParam.MEDIA_CONTROLS_ENABLED
          ));

    return (
      this.device.isDisplayMediaAvailable &&
      (agentSS || customerSS) &&
      this.device.isScreenShareFeatureSupported()
    );
  }

  public isLeaveAvailable(interaction: IInteraction) {
    const isPopup =
      this.user.isCustomer &&
      interaction.getOrigin() === ConversationOriginEnum.POPUP;
    const isWidget =
      DeviceService.isIFrame &&
      this.user.isCustomer &&
      interaction.getOrigin() === ConversationOriginEnum.WIDGET;
    // if the leave button is disabled or the media controls are disabled, disable the button
    let isEnabled = this.config.customerParamEnabled(
      CustomerParam.LEAVE_CONTROL_ENABLED
    );
    // && this.config.customerParamEnabled(CustomerParam.MEDIA_CONTROLS_ENABLED);
    // for widget and popup hide
    if (isWidget) {
      isEnabled =
        this.config.customerParamEnabled(
          CustomerParam.LEAVE_CONTROL_WIDGET_ENABLED
        ) && interaction.getType() !== ConversationTypeEnum.voiceCall;
    } else if (isPopup) {
      isEnabled = false;
    }
    return (
      (this.user.isCustomer &&
        (isEnabled || interaction.getOriginMode() === OriginModeEnum.kiosk)) || // but show if is in kiosk mode
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.LEAVE_CONTROL_ENABLED))
    );
  }

  public get isHoldAvailable() {
    return (
      this.config.featureEnabled(FEATURES.CALL_HOLD) &&
      (this.user.isAgent ||
        (this.user.isCustomer &&
          !this.config.featureEnabled(FEATURES.CALL_HOLD_AGENT_ONLY)))
    );
  }

  public get isCaptionsAvailable() {
    return this.config.publicParam(PublicParam.ASR_ENABLED);
  }

  public get isCaptionsControlAvailable() {
    return (
      this.isCaptionsAvailable &&
      this.config.publicParam(PublicParam.ASR_PROVIDERS_CONFIGURED) &&
      ((this.isAgentASRModerator && this.user.isAgent) ||
        this.isParticipantASRModerator)
    );
  }

  public get isTranslationAvailable() {
    return this.config.publicParam(
      PublicParam.ASR_TRANSLATION_PROVIDER_CONFIGURED
    );
  }

  /*
  public get isTranslationControlAvailable() {
    return this.isTranslationAvailable && ((this.user.isAgent && this.isAgentTranslationModerator) || this.isParticipantTranslationModerator);
  }
  */

  get isAgentASRModerator() {
    return (
      (this.config.publicParam(
        PublicParam.ASR_MODERATOR
      ) as RoomModeratorEnum) === RoomModeratorEnum.agent
    );
  }

  get isParticipantASRModerator() {
    return (
      (this.config.publicParam(
        PublicParam.ASR_MODERATOR
      ) as RoomModeratorEnum) === RoomModeratorEnum.participant
    );
  }

  get isQRInvitationAvailable(): boolean {
    return this.config.agentParam(AgentParam.QR_INVITATION_ENABLED);
  }

  get isCustomerInvitationAvailalbe(): boolean {
    return this.config.agentParam(AgentParam.CUSTOMER_INVITATION_ENABLED);
  }

  get isAgentInvitationAvailable(): boolean {
    return this.config.agentParam(AgentParam.AGENT_INVITATION_ENABLED);
  }

  /** the agent controls the translation for all participants 
  get isAgentTranslationModerator() {
    return (
      (this.config.publicParam(PublicParam.ASR_TRANSLATION_MODERATOR) as RoomModeratorEnum) === RoomModeratorEnum.agent
    );
  }*/

  /** each participant controls his own translation 
  get isParticipantTranslationModerator() {
    return (
      (this.config.publicParam(PublicParam.ASR_TRANSLATION_MODERATOR) as RoomModeratorEnum) === RoomModeratorEnum.participant
    );
  }*/

  public get defaultFacingMode(): VideoFacingModeEnum {
    return this.user.isCustomer &&
      this.config.customerParamEnabled(
        CustomerParam.DEFAULT_FACING_MODE_ENVIRONMENT
      )
      ? VideoFacingModeEnum.Environment
      : VideoFacingModeEnum.User;
  }

  public get canExportRecording(): boolean {
    return (
      (this.user.isAgent &&
        this.config.agentParamEnabled(AgentParam.COMPOSITION_EXPORT_ENABLED)) ||
      this.user.isSupervisor ||
      this.user.isAdmin
    );
  }

  public get canSaveCustomerMetadata(): boolean {
    return this.config.publicParam(PublicParam.CUSTOMER_METADATA_ENABLED);
  }

  /**
   * Remove any blacklisted properties
   * @param metadata customer and interaction metadata
   * note: we have a circular dependency issue with analytics service, that's why we use static
   */
  public static filterCustomerMetadata(
    blacklist: string[],
    metadata: { [key: string]: any }
  ): {
    [key: string]: any;
  } {
    const filtered = Object.keys(metadata).reduce((map, key) => {
      return (blacklist || []).some(
        // 'context.' is a genesys prefix.
        // do not filter out auvious properties, we need them
        (b) => b === key || key === `context.${b}`
      ) ||
        key.includes("auvious") ||
        key.includes("genesys")
        ? map
        : { ...map, [key]: metadata[key] };
    }, {});
    return filtered;
  }
}
