import {
  ApplicationTypeEnum,
  ConversationChannelEnum,
  ConversationDestinationEnum,
  ConversationMetadataEnum,
  ConversationOriginEnum,
  ConversationTypeEnum,
  IInteraction,
  OriginModeEnum,
  WhisperModeEnum,
} from "../../core-ui";
import {
  DEFAULT_CUSTOMER_ID,
  KEY_APPOINTMENT_ID,
  KEY_ROOM_NAME,
  PARAM_CALL_TYPE,
  WIDGET_CONVERSATION_TYPE,
  WIDGET_MODE,
} from "../app.enums";
import { mapToJSON } from "../app.utils";

export interface IDigitalConnectInteraction {
  conversationId: string; // auvious digital-connect id (internal id)
  created: string;
  interactionId: string; // integration id (external id)
  customProperties?: IDigitalConnectInteractionMetadata;
  customerId?: string;
  agentId?: string;
}

export interface IDigitalConnectInteractionMetadata {
  conversationType?: string;
  [key: string]: any;
}

export class DigitalConnectInteraction implements IInteraction {
  private _id: string;
  private _room: string;
  private _digitalConnectId: string;
  private _origin: ConversationOriginEnum;
  private _type: ConversationTypeEnum;
  private _parentFrameUrl: string;
  private _originMode: OriginModeEnum;
  private _destination: ConversationDestinationEnum;
  private _meta: Map<ConversationMetadataEnum, any>;
  private _channel: ConversationChannelEnum;
  private _customerId: string;
  private _agentId: string;
  private _integrationData;
  private _appointmentId: string;
  private _whisperMode: WhisperModeEnum;

  constructor(interaction: IDigitalConnectInteraction) {
    this._integrationData = interaction;

    // interactionId: talkdesk interaction id
    // conversationId: Auvious digital connect id

    this._id = interaction.interactionId || interaction.conversationId;
    this._digitalConnectId = interaction.conversationId;
    //

    this._origin = !!interaction.customProperties[KEY_APPOINTMENT_ID]
      ? ConversationOriginEnum.APPOINTMENT
      : ConversationOriginEnum.WIDGET;
    this._originMode =
      interaction.customProperties[WIDGET_MODE] || OriginModeEnum.website;
    this._channel = ConversationChannelEnum.digitalConnect;
    this._customerId = interaction.customerId || DEFAULT_CUSTOMER_ID;
    this._agentId = interaction.agentId;
    this._room = interaction.customProperties[KEY_ROOM_NAME];
    this._appointmentId = interaction.customProperties[KEY_APPOINTMENT_ID];
    this._meta = new Map();
    this._type =
      (interaction.customProperties
        ?.conversationType as ConversationTypeEnum) ||
      interaction.customProperties[WIDGET_CONVERSATION_TYPE] ||
      ConversationTypeEnum.chat;

    if (interaction.customProperties) {
      Object.keys(interaction.customProperties).forEach((key) =>
        this._meta.set(
          key as ConversationMetadataEnum,
          interaction.customProperties[key]
        )
      );
    }
  }

  getId(): string {
    return this._id;
  }
  getIntegrationId(): string {
    return this._id;
  }
  getDigitalConnectId(): string {
    return this._digitalConnectId;
  }
  hasRoom(): boolean {
    return !!this._room;
  }
  getRoom(): string {
    return this._room;
  }
  setRoom(room: string) {
    this._room = room;
  }
  getOrigin(): ConversationOriginEnum {
    return this._origin;
  }
  setOrigin(value: ConversationOriginEnum) {
    this._origin = value;
  }
  getType(): ConversationTypeEnum {
    return this._type;
  }
  setType(value: ConversationTypeEnum) {
    this._type = value;
  }
  getCustomerId(): string {
    return this._customerId;
  }
  getCustomerEmail(): string {
    return this._integrationData.customProperties?.email;
  }
  getCustomerName(): string {
    return (
      (!!this._integrationData.customProperties?.firstName
        ? this._integrationData.customProperties?.firstName
        : "") +
      (!!this._integrationData.customProperties?.lastName
        ? " " + this._integrationData.customProperties?.lastName
        : "")
    );
  }
  getCustomerPhoneNumber(): string {
    return null;
  }
  setParentFrameUrl(value: string) {
    this._parentFrameUrl = value;
  }
  getParentFrameUrl(): string {
    return this._parentFrameUrl;
  }
  getOriginMode(): OriginModeEnum {
    return this._originMode;
  }
  setOriginMode(value: OriginModeEnum) {
    this._originMode = value;
  }
  getDestination(): ConversationDestinationEnum {
    return this._destination;
  }
  setDestination(value: ConversationDestinationEnum) {
    this._destination = value;
  }
  setMetadataItem(key: ConversationMetadataEnum, value: any) {
    this._meta.set(key, value);
  }
  getMetadataItem(key: ConversationMetadataEnum) {
    return this._meta.get(key);
  }
  getMetadata() {
    return mapToJSON(this._meta);
  }
  getChannel(): ConversationChannelEnum {
    return this._channel;
  }
  setChannel(value: ConversationChannelEnum) {
    this._channel = value;
  }
  getAppointmentId(): string {
    return this._appointmentId;
  }
  setAppointmentId(value: string) {
    if (!this._appointmentId) {
      this._appointmentId = value;
    }
  }
  setCustomerId(value: string) {
    this._customerId = value;
  }
  supportsMessages(): boolean {
    return false;
  }
  supportsEmails(): boolean {
    return false;
  }
  supportsSMS(): boolean {
    return false;
  }
  supportsScheduledCalls(): boolean {
    return false;
  }
  setWhisperMode(value: WhisperModeEnum) {
    this._whisperMode = value;
  }
  getWhisperMode(): WhisperModeEnum {
    return this._whisperMode;
  }
  isCustomerInvited(): boolean {
    return false;
  }
  isTransfer(): boolean {
    return false;
  }
  isIntegrationInteractionAvailable(): boolean {
    return true;
  }

  toString?(): string {
    return JSON.stringify({
      id: this.getId(),
      room: this.getRoom(),
      origin: this.getOrigin(),
      type: this.getType(),
      customerId: this.getCustomerId(),
      customerEmail: this.getCustomerEmail(),
      customerName: this.getCustomerName(),
      customerPhoneNumber: this.getCustomerPhoneNumber(),
      parentFrameUrl: this.getParentFrameUrl(),
      originMode: this.getOriginMode(),
      destination: this.getDestination(),
      channel: this.getChannel(),
      appointmentId: this.getAppointmentId(),
      metadata: mapToJSON(this._meta),
      integrationData: this._integrationData,
      integrationType: ApplicationTypeEnum.TalkdeskOpenID,
    });
  }
  getCustomerLink(ticketUrl: string): string {
    switch (this._type) {
      case ConversationTypeEnum.voiceCall:
        const l = new URL(ticketUrl);
        // we need to state call type because on audio calls, we hide the cam button
        l.searchParams.set(PARAM_CALL_TYPE, ConversationTypeEnum.voiceCall);
        return l.toString();
      default:
        return ticketUrl;
    }
  }
  getQueueId(): string {
    return null;
  }
}
