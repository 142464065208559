import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  signal,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IApplicationDTO } from "../../../core-ui/models/IApplication";
import { ConversationService } from "../../services/conversation.service";
import { combineLatestWith, filter, Subscription } from "rxjs";
import {
  CUSTOMER_CALL_ACCEPTED_ACK,
  PARAM_CONVERSATION_ORIGIN,
  PARAM_CONVERSATION_CHANNEL,
  PARAM_APPOINTMENT_ID,
} from "../../app.enums";
import { IConversation, IMember } from "@auvious/integrations";
import { AppointmentService } from "../../../core-ui/services/appointment.service";
import {
  IAppointment,
  IAppointmentRouting,
  IAppointmentRoutingGenesysCloudQueue,
  AppointmentParticipantStateEnum,
} from "../../../core-ui/models/Appointment";
import {
  IThemeOptions,
  ThemeOptions,
} from "../../../core-ui/models/application-options/ThemeOptions";
import { AppConfigService } from "../../../core-ui/services/app.config.service";
import {
  ConversationOriginEnum,
  DEFAULT_APPOINTMENT_CHAT_MODE,
  PARAM_TICKET_ID,
  PublicParam,
  UserRoleEnum,
} from "../../../core-ui";
import { GenericErrorHandler } from "../../services/error-handlers.service";
import { isDebugOn } from "../../../core-ui/services/utils";

@Component({
  selector: "app-appointment-page",
  templateUrl: "./appointment.component.html",
  styleUrls: ["./appointment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentPageComponent implements OnInit, OnDestroy {
  options: IApplicationDTO;
  subscription = new Subscription();
  state = signal("idle");
  startAt: Date;
  appointment: IAppointment<IAppointmentRouting>;
  theme: IThemeOptions;
  timezone: string;
  isDebug = !!isDebugOn();
  isLoading = false;
  isError = false;
  errorMessage;
  queueName: string;

  constructor(
    private route: ActivatedRoute,
    private conversation: ConversationService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private appointmentService: AppointmentService,
    private handler: GenericErrorHandler,
    config: AppConfigService
  ) {
    this.theme = ThemeOptions.create(config.publicParam(PublicParam.THEME));
  }

  async ngOnInit() {
    this.subscription.add(
      this.appointmentService.appointmentChanged$
        .pipe(filter((a) => !!a))
        .subscribe((appointment) => {
          this.appointment = appointment;
          this.startAt = this.appointment.start;
          this.timezone = this.appointment.timezone;

          this.appointmentService.updateParticipantState(
            this.appointment.id,
            this.appointment.participantIds.find((p) => p.type === "CUSTOMER")
              .id,
            AppointmentParticipantStateEnum.WAITING
          );

          // fill in integration-specific data
          switch (this.appointment.interaction.routing.type) {
            case "GENESYS_CLOUD_QUEUE":
              this.queueName = (
                this.appointment.interaction
                  .routing as IAppointmentRoutingGenesysCloudQueue
              ).properties.queueName;
              break;
          }
        })
    );

    this.subscription.add(
      this.conversation.connecting$.subscribe((_) => {
        this.state.set("connecting");
      })
    );
    this.subscription.add(
      this.conversation.connected$.subscribe((_) => {
        this.state.set("connected");
      })
    );
    this.subscription.add(
      this.conversation.agentNotifified$.subscribe((_) => {
        this.state.set("agent_notified");
      })
    );
    this.subscription.add(
      this.conversation.agentJoined$.subscribe((agent: IMember) => {
        this.state.set("agent_joined");
        // ngOnInit was not running in conferenceComponent unless we use ngZone
        this.router.navigate(
          ["/t", this.route.snapshot.paramMap.get(PARAM_TICKET_ID)],
          {
            queryParams: {
              [PARAM_CONVERSATION_ORIGIN]: ConversationOriginEnum.APPOINTMENT,
              [PARAM_CONVERSATION_CHANNEL]:
                this.appointment.interaction.routing.properties.chatMode ||
                this.appointment.metadata.chatMode ||
                DEFAULT_APPOINTMENT_CHAT_MODE,
            },
          }
        );
      })
    );

    // on a page refresh, the agentJoined event is sent before the started event.
    // in that case, we don't have a conversation id to send the message to.
    // wait for both to email a value and send the ACK message
    this.subscription.add(
      this.conversation.agentJoined$
        .pipe(combineLatestWith(this.conversation.started$))
        .subscribe((_) => {
          this.conversation.sendMessage(CUSTOMER_CALL_ACCEPTED_ACK, "notice");
        })
    );

    this.subscription.add(
      this.conversation.started$.subscribe((conversation: IConversation) => {
        this.state.set("started");
      })
    );
    this.subscription.add(
      this.conversation.agentLeft$.subscribe((_) => {
        this.state.set("agent_left");
        this.cd.detectChanges();
      })
    );
    this.subscription.add(
      this.conversation.customerLeft$.subscribe((_) => {
        this.state.set("customer_left");
      })
    );
    this.subscription.add(
      this.conversation.ended$.subscribe((_) => {
        this.state.set("ended");
        if (!this.isError) {
          // redirect
          this.router.navigate([
            "/thank-you",
            UserRoleEnum.customer.toLowerCase(),
          ]);
        }
      })
    );

    this.subscription.add(
      this.conversation.error$.subscribe((e: any) => {
        this.state.set("error");
        // e.data means the error comes from genesys
        if (e.data || e.message) {
          this.isError = true;
          this.errorMessage =
            e.message || e.data?.errors?.[0]?.response?.responseJSON?.message;
        }
        this.handler.handleError(e);
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    if (this.state() !== "agent_joined") {
      this.appointmentService.updateParticipantState(
        this.appointment.id,
        this.appointment.participantIds.find((p) => p.type === "CUSTOMER").id,
        AppointmentParticipantStateEnum.LEFT
      );
    }
    this.subscription.unsubscribe();
  }
}
