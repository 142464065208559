import { ParamMap } from "@angular/router";
import { Util } from "@auvious/common";
import {
  PARAM_CALL_TYPE,
  PARAM_CONVERSATION_DESTINATION,
  PARAM_ROOM_ID,
  PARAM_CONVERSATION_ID,
  PARAM_CONVERSATION_ORIGIN,
  PARAM_GENESYS_ENV,
  PARAM_KIOSK_MODE,
  PARAM_CUSTOMER_ID,
  PARAM_CXEXCHANGE_ISS,
  PARAM_GUEST,
  PARAM_WHISPER_MODE,
} from "../../app/app.enums";

import {
  ConversationOriginEnum,
  ConversationDestinationEnum,
  decodeBase64,
} from "..";
import { ConversationTypeEnum, WhisperModeEnum } from "../core-ui.enums";
import { discoverApplicationId } from "../../app/app.utils";

export class AuthState {
  static fromQueryParamMap(
    queryParamMap: ParamMap | URLSearchParams
  ): AuthState {
    // falling back to state attributes, in order for refreshes to work when refresh token fails
    const prevState = queryParamMap.has("state")
      ? AuthState.fromSerializedState(queryParamMap.get("state"))
      : null;
    const room = queryParamMap.get(PARAM_ROOM_ID) ?? prevState?.room;
    const applicationId = discoverApplicationId(queryParamMap);
    const conversationId =
      queryParamMap.get(PARAM_CONVERSATION_ID) ?? prevState?.conversationId;
    const pcEnvironment =
      queryParamMap.get(PARAM_GENESYS_ENV) ?? prevState?.pcEnvironment;
    const conversationOrigin =
      (queryParamMap.get(
        PARAM_CONVERSATION_ORIGIN
      ) as ConversationOriginEnum) ?? prevState?.conversationOrigin;
    const guest =
      (queryParamMap.has(PARAM_GUEST) &&
        queryParamMap.get(PARAM_GUEST) !== "false") ||
      prevState?.guest;
    const kioskMode =
      (queryParamMap.has(PARAM_KIOSK_MODE) &&
        queryParamMap.get(PARAM_KIOSK_MODE) !== "false") ||
      prevState?.kioskMode;
    const whisperMode =
      (queryParamMap.get(PARAM_WHISPER_MODE) as WhisperModeEnum) ||
      prevState?.whisperMode;
    const destination =
      (queryParamMap.get(
        PARAM_CONVERSATION_DESTINATION
      ) as ConversationDestinationEnum) ?? prevState?.conversationDestination;
    const callType =
      (queryParamMap.get(PARAM_CALL_TYPE) as ConversationTypeEnum) ??
      prevState?.conversationType;
    const customerId =
      queryParamMap.get(PARAM_CUSTOMER_ID) ?? prevState?.customerId;
    const iss = queryParamMap.get(PARAM_CXEXCHANGE_ISS) ?? prevState?.iss;

    return new AuthState(
      pcEnvironment,
      room,
      applicationId,
      conversationId,
      conversationOrigin,
      guest,
      kioskMode,
      destination,
      callType,
      customerId,
      iss,
      whisperMode
    );
  }

  static fromSerializedState(state: string): AuthState {
    try {
      const data = JSON.parse(decodeBase64(state));
      return new AuthState(
        data.pcEnvironment,
        data.room,
        data.applicationId,
        data.conversationId,
        data.conversationOrigin,
        data.guest,
        data.kioskMode,
        data.conversationDestination,
        data.conversationType,
        data.customerId,
        data.iss,
        data.whisperMode
      );
    } catch (ex) {
      return null;
    }
  }

  private constructor(
    private _pcEnvironment: string,
    private _room: string,
    private _applicationId: string,
    private _conversationId: string,
    private _conversationOrigin: ConversationOriginEnum,
    private _guest: boolean,
    private _kioskMode: boolean,
    private _conversationDestination: ConversationDestinationEnum,
    private _conversationType: ConversationTypeEnum,
    private _customerId: string,
    private _iss: string,
    private _whisperMode: WhisperModeEnum
  ) {}

  public get pcEnvironment() {
    return this._pcEnvironment;
  }

  public get room() {
    return this._room;
  }

  public get applicationId() {
    return this._applicationId;
  }

  public get conversationId() {
    return this._conversationId;
  }

  public get conversationOrigin() {
    return this._conversationOrigin;
  }

  public get conversationDestination() {
    return this._conversationDestination;
  }

  public get guest(): boolean {
    return this._guest;
  }

  public get kioskMode(): boolean {
    return this._kioskMode;
  }

  public get whisperMode(): WhisperModeEnum {
    return this._whisperMode;
  }

  public get conversationType(): ConversationTypeEnum {
    return this._conversationType;
  }

  public get customerId(): string {
    return this._customerId;
  }

  public get iss(): string {
    return this._iss;
  }

  public setConversationId(value) {
    this._conversationId = value;
  }

  public serialize() {
    return btoa(
      JSON.stringify({
        pcEnvironment: this.pcEnvironment,
        room: this.room,
        applicationId: this.applicationId,
        conversationId: this.conversationId,
        conversationOrigin: this.conversationOrigin,
        conversationDestination: this.conversationDestination,
        conversationType: this.conversationType,
        guest: this.guest,
        kioskMode: this.kioskMode,
        whisperMode: this._whisperMode,
        customerId: this.customerId,
        iss: this.iss,
        random: Util.uuidgen(Date.now().toString()),
      })
    );
  }
}
