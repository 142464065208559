import {
  ConversationTypeEnum,
  IInteraction,
  ConversationOriginEnum,
  OriginModeEnum,
  ConversationDestinationEnum,
  ConversationMetadataEnum,
} from "../../core-ui";
import { Util } from "@auvious/common";
import { DEFAULT_CUSTOMER_ID, PARAM_CALL_TYPE } from "../app.enums";
import {
  ApplicationTypeEnum,
  ConversationChannelEnum,
  WhisperModeEnum,
} from "../../core-ui/core-ui.enums";
import { GenesysInteraction } from "./GenesysInteraction";
import { DigitalConnectInteraction } from "./DigitalConnectInteraction";
import { mapToJSON } from "../app.utils";
import { NiceInteraction } from "./NiceInteraction";
import { VerintInteraction } from "./VerintInteraction";

interface IInteractionData {
  id: string;
  room: string;
  origin: ConversationOriginEnum;
  type: ConversationTypeEnum;
  customerId: string;
  customerName: string;
  parentFrameUrl: string;
  originMode: OriginModeEnum;
  destination: ConversationDestinationEnum;
  channel: ConversationChannelEnum;
  metadata: object;
}

export class Interaction implements IInteraction {
  private _id: string;
  private _room: string;
  private _origin: ConversationOriginEnum;
  private _type: ConversationTypeEnum;
  private _originMode: OriginModeEnum;
  private _destination: ConversationDestinationEnum;
  private _customerId: string;
  private _customerName: string;
  private _parentFrameUrl: string;
  private _meta: Map<ConversationMetadataEnum, any>;
  private _channel: ConversationChannelEnum;
  private _integrationData;
  private _appointmentId: string;
  private _whisperMode: WhisperModeEnum;

  constructor(room: string, id?: string, data?: IInteractionData) {
    this._room = room;
    this._id = id || room || Util.uuidgen(Date.now().toString());

    if (data) {
      this._integrationData = data;
      this._origin = data.origin;
      this._type = data.type;
      this._originMode = data.originMode;
      this._destination = data.destination;
      this._channel = data.channel;
      this._customerId = data.customerId;
      this._customerName = data.customerName;
      this._meta = new Map();
      if (data.metadata) {
        Object.keys(data.metadata).forEach((key) =>
          // @ts-expect-error
          this._meta.set(key, data.metadata[key])
        );
      }
    } else {
      this._origin = ConversationOriginEnum.CHAT;
      this._type = ConversationTypeEnum.videoCall;
      this._originMode = OriginModeEnum.website;
      this._destination = ConversationDestinationEnum.EMBEDDED;
      this._channel = ConversationChannelEnum.unknown;
      this._customerId = DEFAULT_CUSTOMER_ID;
      this._customerName = null;
      this._meta = new Map();
    }
  }

  getId() {
    return this._id;
  }

  getIntegrationId(): string {
    return this._id;
  }

  hasRoom() {
    return !!this._room;
  }

  getRoom() {
    return this._room;
  }

  setRoom(room: string) {
    this._room = room;
  }

  getOrigin() {
    return this._origin;
  }

  setOrigin(value: ConversationOriginEnum) {
    this._origin = value;
  }

  getType() {
    return this._type;
  }

  setType(value: ConversationTypeEnum) {
    this._type = value;
  }

  getCustomerId() {
    return this._customerId;
  }

  setCustomerId(value: string) {
    this._customerId = value;
  }

  getCustomerName() {
    return this._customerName;
  }

  getAppointmentId(): string {
    return this._appointmentId;
  }

  setAppointmentId(value: string) {
    this._appointmentId = value;
  }

  isCustomerInvited() {
    return true;
  }

  isTransfer() {
    return false;
  }

  getCustomerEmail() {
    return null;
  }

  getCustomerPhoneNumber() {
    return null;
  }

  setParentFrameUrl(value: string) {
    this._parentFrameUrl = value;
  }

  getParentFrameUrl() {
    return this._parentFrameUrl;
  }

  getOriginMode() {
    return this._originMode;
  }

  setOriginMode(value: OriginModeEnum) {
    this._originMode = value;
  }

  supportsMessages() {
    return false;
  }

  supportsEmails() {
    return false;
  }

  supportsSMS() {
    return false;
  }

  supportsScheduledCalls() {
    return false;
  }

  setDestination(value: ConversationDestinationEnum) {
    this._destination = value;
  }

  getDestination() {
    return this._destination;
  }

  setMetadataItem(key: ConversationMetadataEnum, value: any) {
    this._meta.set(key, value);
  }

  getMetadataItem(key: ConversationMetadataEnum): any {
    return this._meta.get(key);
  }

  getMetadata() {
    return mapToJSON(this._meta);
  }

  getChannel(): ConversationChannelEnum {
    return this._channel;
  }

  setChannel(value: ConversationChannelEnum) {
    this._channel = value;
  }

  setWhisperMode(value: WhisperModeEnum) {
    this._whisperMode = value;
  }

  getWhisperMode(): WhisperModeEnum {
    return this._whisperMode;
  }

  isIntegrationInteractionAvailable(): boolean {
    return false;
  }

  toString() {
    return JSON.stringify({
      id: this.getId(),
      room: this.getRoom(),
      origin: this.getOrigin(),
      type: this.getType(),
      customerId: this.getCustomerId(),
      customerEmail: this.getCustomerEmail(),
      customerName: this.getCustomerName(),
      customerPhoneNumber: this.getCustomerPhoneNumber(),
      parentFrameUrl: this.getParentFrameUrl(),
      originMode: this.getOriginMode(),
      destination: this.getDestination(),
      channel: this.getChannel(),
      appointmentId: this.getAppointmentId(),
      metadata: mapToJSON(this._meta),
      integrationData: this._integrationData,
      integrationType: ApplicationTypeEnum.StandaloneOpenID,
    });
  }

  getCustomerLink(ticketUrl: string): string {
    switch (this._type) {
      case ConversationTypeEnum.voiceCall:
        const l = new URL(ticketUrl);
        // we need to state call type because on audio calls, we hide the cam button
        l.searchParams.set(PARAM_CALL_TYPE, ConversationTypeEnum.voiceCall);
        return l.toString();
      default:
        return ticketUrl;
    }
  }

  getQueueId(): string {
    return null;
  }
}

export class InteractionFactory {
  public static fromJSON(json: string): IInteraction {
    try {
      const data = JSON.parse(json);
      switch (data.integrationType) {
        case ApplicationTypeEnum.StandaloneOpenID:
          return new Interaction(
            data.room,
            data.id,
            data.integrationData ?? data
          );

        case ApplicationTypeEnum.GenesysCloud:
          return new GenesysInteraction({
            ...data.integrationData,
            room: data.room,
          });

        case ApplicationTypeEnum.TalkdeskOpenID:
          return new DigitalConnectInteraction(data.integrationData);
        case ApplicationTypeEnum.NiceOpenID:
          return new NiceInteraction(data.integrationData);
        case ApplicationTypeEnum.verintOpenID:
          return new VerintInteraction(data.integrationData);
      }
    } catch (ex) {
      throw ex;
    }
  }
}
