import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PARAM_TICKET_ID } from "../../core-ui/core-ui.enums";

import {
  WelcomePageComponent,
  ConferencePageComponent,
  ThankYouComponent,
  ApplicationsPageComponent,
  SetupPageComponent,
  ErrorPageComponent,
  WaitPageComponent,
  CompositionsPageComponent,
  AppointmentPageComponent,
  CobrowsePageComponent,
  RedirectPageComponent,
  PermissionsPageComponent,
} from "../pages";

import {
  CompatibilityGuard,
  AuthAgentGuard,
  AuthCustomerGuard,
  SyncGuardHelper,
  AuthRedirectGuard,
  LicenseAgentGuard,
  AppointmentConversationGuard,
  RedirectGuard,
} from "./guards";
import { AgentCompatibilityGuard } from "./guards/agent.compatibility.guard";
import { AppAgentGuard } from "./guards/app.agent.guard";
import { AppCustomerGuard } from "./guards/app.customer.guard";
import { RtcRegistrationGuard } from "./guards/RtcRegistrationGuard";

import {
  TicketResolver,
  InteractionResolver,
  InteractionRoomResolver,
  SettingsResolver,
} from "./resolvers";
import { CobrowseGuard } from "./guards/cobrowse.guard";
import { LocaleGuard } from "./guards/locale.guard";
import { ActivityPageComponent } from "../pages/activity/activity.component";

const routes: Routes = [
  {
    path: "a",
    component: ConferencePageComponent,
    canActivate: [SyncGuardHelper],
    resolve: {
      interaction: InteractionRoomResolver,
    },
    data: {
      guards: [
        CompatibilityGuard,
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        LicenseAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
  },
  {
    path: `t/:${PARAM_TICKET_ID}`,
    component: ConferencePageComponent,
    canActivate: [SyncGuardHelper],
    resolve: {
      interaction: InteractionRoomResolver,
    },
    data: {
      guards: [
        CompatibilityGuard,
        AppCustomerGuard,
        AuthCustomerGuard,
        RtcRegistrationGuard,
        AppointmentConversationGuard,
        LocaleGuard,
      ],
    },
  },
  {
    path: `wait/:${PARAM_TICKET_ID}`,
    component: WaitPageComponent,
    resolve: {
      ticket: TicketResolver,
    },
    canActivate: [CompatibilityGuard, AppCustomerGuard, LocaleGuard],
  },
  {
    path: `q/:${PARAM_TICKET_ID}`,
    component: AppointmentPageComponent,
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        CompatibilityGuard,
        AppCustomerGuard,
        AuthCustomerGuard,
        RtcRegistrationGuard,
        AppointmentConversationGuard,
        LocaleGuard,
      ],
    },
  },
  {
    path: "welcome",
    component: WelcomePageComponent,
    canActivate: [SyncGuardHelper],
    resolve: {
      interaction: InteractionResolver,
    },
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        AuthRedirectGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
  },
  {
    path: "activity",
    component: ActivityPageComponent,
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        AuthRedirectGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
  },
  {
    path: "redirect",
    component: RedirectPageComponent,
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        AuthRedirectGuard,
      ],
    },
  },
  {
    path: "cobrowse",
    component: CobrowsePageComponent,
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
        CobrowseGuard,
      ],
    },
  },
  {
    path: "apps",
    component: ApplicationsPageComponent,
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        LocaleGuard,
      ],
    },
  },
  {
    path: "setup",
    component: SetupPageComponent,
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
  },

  {
    path: "settings",
    canActivate: [SyncGuardHelper],
    resolve: {
      data: SettingsResolver,
    },
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
    loadChildren: () =>
      import("../../modules/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
  },

  {
    path: "exports",
    component: CompositionsPageComponent,
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
  },
  {
    path: "reports",
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
    loadChildren: () =>
      import("../../modules/reports/reports.module").then(
        (m) => m.ReportsModule
      ),
  },
  {
    path: "interaction",
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
    loadChildren: () =>
      import("../../modules/interaction/interaction.module").then(
        (m) => m.InteractionModule
      ),
  },
  {
    path: "schedule",
    canActivate: [SyncGuardHelper],
    resolve: {
      interaction: InteractionResolver,
    },
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
    loadChildren: () =>
      import("../../modules/schedule/schedule.module").then(
        (m) => m.ScheduleModule
      ),
  },
  {
    path: "audit",
    canActivate: [SyncGuardHelper],
    data: {
      guards: [
        AgentCompatibilityGuard,
        AppAgentGuard,
        AuthAgentGuard,
        RtcRegistrationGuard,
        LocaleGuard,
      ],
    },
    loadChildren: () =>
      import("../../modules/audit/audit.module").then((m) => m.AuditModule),
  },
  {
    path: "thank-you/:role",
    component: ThankYouComponent,
  },
  {
    path: "permissions",
    component: PermissionsPageComponent,
  },
  {
    path: "error/:code",
    component: ErrorPageComponent,
    canActivate: [RedirectGuard],
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "error/404",
  },
  {
    path: "**",
    redirectTo: "error/page-not-found",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
  providers: [
    CompatibilityGuard,
    AgentCompatibilityGuard,
    AppAgentGuard,
    AppCustomerGuard,
    AuthAgentGuard,
    AuthCustomerGuard,
    RtcRegistrationGuard,
    SyncGuardHelper,
    LocaleGuard,
    LicenseAgentGuard,
    RedirectGuard,
  ],
})
export class AppRoutingModule {}
