import { Component, OnInit } from "@angular/core";
import { DeviceService, UserRoleEnum } from "../../../core-ui";
import { UserService } from "../../../core-ui/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-thank-you",
  templateUrl: "./thank-you.component.html",
  styleUrls: ["./thank-you.component.scss"],
})
export class ThankYouComponent implements OnInit {
  isCustomer: boolean;
  isAgent: boolean;

  constructor(
    private deviceService: DeviceService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.userService.getActiveUser()?.unauthenticate();
    const role = this.route.snapshot.paramMap.get("role");
    this.isCustomer = role === UserRoleEnum.customer.toLowerCase();
    this.isAgent = role === UserRoleEnum.agent.toLowerCase();
  }
}
