import {
  AssetTypeEnum,
  COLOR,
  DEFAULT_AR_POINTER_COLORS,
  SizeEnum,
} from "../../core-ui.enums";
import { IQueueAsset } from "../interfaces";
export { ColorEvent } from "ngx-color";

// eslint-disable-next-line no-shadow
export enum ThemeParam {
  BACKGROUND_COLOR = "backgroundColor",
  TEXT_COLOR = "color",
  LOGO_URL = "logoUrl",
  QUEUE_VIDEO_URL = "videoUrl", // we store image and other urls to this prop, due to backwards compatibility
  QUEUE_ASSET_TYPE = "queueAssetType", // and use this type to what actually the video url is for.
  ASSET_PER_QUEUE_ENABLED = "assetPerQueueEnabled",
  ASSET_PER_QUEUE = "assetPerQueue",
  DARK_MODE = "darkMode",
  EMBEDDABLE_VIDEO_URL = "embeddableVideoUrl",
  CAPTIONS_FONT_COLOR = "captionsFontColor",
  CAPTIONS_FONT_BACKGROUND_COLOR = "captionsFontBackgroundColor",
  CAPTIONS_BACKGROUND_COLOR = "captionsBackgroundColor",
  CAPTIONS_FONT_SIZE = "captionsFontSize",
  BUTTON_PRIMARY_BACKGROUND_COLOR = "buttonPrimaryBackgroundColor",
  CUSTOM_THEME_CSS = "customThemeCSS",
  AR_POINTER_COLORS = "ARPointerColors",
  CALL_HOLD_ASSETS = "callHoldAssets",
  CALL_HOLD_ASSET_ENABLED = "callHoldAssetEnabled",
}

export interface IThemeOptions {
  [ThemeParam.BACKGROUND_COLOR]?: string;
  [ThemeParam.TEXT_COLOR]?: string;
  [ThemeParam.LOGO_URL]?: string;
  [ThemeParam.QUEUE_VIDEO_URL]?: string;
  [ThemeParam.QUEUE_ASSET_TYPE]?: AssetTypeEnum;
  [ThemeParam.ASSET_PER_QUEUE_ENABLED]?: boolean;
  [ThemeParam.ASSET_PER_QUEUE]?: IQueueAsset[];
  [ThemeParam.DARK_MODE]?: boolean;
  [ThemeParam.EMBEDDABLE_VIDEO_URL]?: string;
  [ThemeParam.CAPTIONS_FONT_COLOR]?: string;
  [ThemeParam.CAPTIONS_FONT_BACKGROUND_COLOR]?: string;
  [ThemeParam.CAPTIONS_BACKGROUND_COLOR]?: string;
  [ThemeParam.CAPTIONS_FONT_SIZE]?: SizeEnum;
  [ThemeParam.BUTTON_PRIMARY_BACKGROUND_COLOR]?: string;
  [ThemeParam.CUSTOM_THEME_CSS]?: string;
  [ThemeParam.AR_POINTER_COLORS]?: string[];
  [ThemeParam.CALL_HOLD_ASSETS]?: IQueueAsset[];
  [ThemeParam.CALL_HOLD_ASSET_ENABLED]?: boolean;
}

export class ThemeOptions implements IThemeOptions {
  public [ThemeParam.BACKGROUND_COLOR] = null;
  public [ThemeParam.TEXT_COLOR] = null;
  public [ThemeParam.LOGO_URL] = null;
  public [ThemeParam.QUEUE_VIDEO_URL] = null;
  public [ThemeParam.QUEUE_ASSET_TYPE] = AssetTypeEnum.video;
  public [ThemeParam.ASSET_PER_QUEUE_ENABLED] = false;
  public [ThemeParam.ASSET_PER_QUEUE] = [];
  public [ThemeParam.DARK_MODE] = false;
  public [ThemeParam.EMBEDDABLE_VIDEO_URL] = null;
  public [ThemeParam.CAPTIONS_FONT_COLOR] = "#ffffff";
  public [ThemeParam.CAPTIONS_BACKGROUND_COLOR] = null;
  public [ThemeParam.CAPTIONS_FONT_BACKGROUND_COLOR] = null;
  public [ThemeParam.CAPTIONS_FONT_SIZE] = SizeEnum.small;
  public [ThemeParam.BUTTON_PRIMARY_BACKGROUND_COLOR] = null;
  public [ThemeParam.CUSTOM_THEME_CSS] = null;
  public [ThemeParam.AR_POINTER_COLORS] = [...DEFAULT_AR_POINTER_COLORS];
  public [ThemeParam.CALL_HOLD_ASSETS] = [];
  public [ThemeParam.CALL_HOLD_ASSET_ENABLED] = false;

  static create(options: IThemeOptions): IThemeOptions {
    const instance = new ThemeOptions();
    return { ...instance, ...options };
  }
}
