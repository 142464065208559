<div
  class="hold-container fx-column fx-align-center fx-justify-center"
  [class.hold-container-enabled]="enabled"
>
  <ng-container *ngIf="!isImageAvailable && !isVideoAvailable">
    <app-freeze-frame
      class="frame"
      [stream]="stream"
      [local]="true"
      [mirror]="true"
      [render]="enabled"
      *ngIf="videoReady"
    >
    </app-freeze-frame>
  </ng-container>

  <ng-container *ngIf="enabled">
    <ng-container *ngIf="isImageAvailable">
      <img
        [src]="imageSrc"
        alt="Your call is on hold"
        class="frame"
        (error)="imgLoadFailed($event)"
      />
    </ng-container>

    <ng-container *ngIf="isVideoAvailable">
      <video
        #videoRef
        class="frame"
        preload="auto"
        autoplay
        playsinline
        webkit-playsinline="true"
        loop
        (canplaythrough)="canPlayThrough($event)"
      >
        <source [src]="videoSrc" />
      </video>
    </ng-container>
  </ng-container>

  <div class="container">
    <av-icon name="pause" class="icon-hold"></av-icon>
    <h2 translate>Your call is on hold</h2>
    <h3 class="blink" translate>Please wait</h3>
  </div>
</div>
