import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";
import {
  ConversationChannelEnum,
  ConversationTypeEnum,
  fadeInOut,
  InteractionMetricEnum,
  slideIn,
  slideInOut,
} from "../../../core-ui";
import { IUser, IUserDetails } from "../../../core-ui/models/IUser";
import { AnalyticsService } from "../../../core-ui/services/analytics.service";
import { NotificationService } from "../../../core-ui/services/notification.service";
import { UserService } from "../../../core-ui/services/user.service";
import { Interaction } from "../../models/Interaction";
import { InvitationService } from "../../services";

@Component({
  selector: "app-page-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"],
  animations: [fadeInOut, slideIn, slideInOut],
})
export class ActivityPageComponent implements OnInit {
  user: IUser;
  userDetails: IUserDetails;

  interactionId: string = "";

  constructor(
    private router: Router,
    private invitation: InvitationService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private notification: NotificationService
  ) {}

  async ngOnInit() {
    try {
      this.user = this.userService.getActiveUser();
      this.userDetails = this.userService.getUserDetails();
    } catch (ex) {
      // console.log(ex);
    }
  }

  public async joinInteraction() {
    try {
      const metrics = await this.analytics.getInteractionMetrics(
        this.interactionId
      );

      const isOngoing =
        moment(metrics.getCallEndAt()).isBefore(
          moment(metrics.getCallStartAt())
        ) ||
        (!metrics.getCallEndAt() &&
          moment().isBefore(moment(metrics.getCallStartAt()).add(1, "h")));

      if (!isOngoing) {
        this.notification.warn("Interaction has ended.");
      } else if (
        metrics.getCallType() !== "video" &&
        metrics.getCallType() !== "audio"
      ) {
        this.notification.warn("Interaction is not a call.");
      } else {
        const id = metrics.getId();
        const room = metrics.getMetric(InteractionMetricEnum.roomName) || id;
        const interaction = new Interaction(room, id, {
          channel: ConversationChannelEnum.unknown,
          customerId: metrics.getCustomerId(),
          customerName: metrics.getCustomerDisplayName(),
          destination: metrics.getCallDestination(),
          id,
          metadata: {},
          origin: metrics.getCallOrigin(),
          originMode: metrics.getCallOriginMode(),
          parentFrameUrl: null,
          room,
          type: metrics.getCallType() as ConversationTypeEnum,
        });

        const queryParams = {};
        const roomLink = new URL(
          this.invitation.prepareSupervisorInvitation(interaction)
        );

        roomLink.searchParams.forEach(
          (value, key) => (queryParams[key] = value)
        );

        this.router.navigate([roomLink.pathname], {
          queryParams,
          queryParamsHandling: "merge",
        });
      }
    } catch (ex) {
      this.notification.warn("Unable to load interaction", {
        body: "Make sure the ID is correct or try again later.",
      });
    }
  }
}
