import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  HostListener,
  Input,
} from "@angular/core";

@Directive({
  selector: "[avOverflowDetect]",
})
export class OverflowDetectDirective implements AfterViewInit {
  @Input() overflowOffsetX = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    setTimeout(() => this.adjustFontSize(), 10);
  }

  @HostListener("window:resize")
  onResize() {
    this.adjustFontSize();
  }

  private isOverflown(element: HTMLElement): boolean {
    return (
      element.scrollWidth > element.clientWidth ||
      element.scrollHeight > element.clientHeight
    );
  }

  private adjustFontSize() {
    const element = this.el.nativeElement;
    let currentSize = parseInt(window.getComputedStyle(element).fontSize);
    const minSize = 12; // Minimum font size
    const step = 1; // Font size decrement step

    while (this.isOverflown(element) && currentSize > minSize) {
      currentSize -= step;
      this.renderer.setStyle(element, "fontSize", `${currentSize}px`);
    }
  }
}
