import { SizeEnum } from "../../core-ui.enums";

// eslint-disable-next-line no-shadow
export enum CustomerParam {
  RATING_REQUIRED = "ratingRequired",
  SCREEN_SHARE_ENABLED = "screenShareEnabled",
  SPEAKER_ENABLED = "speakerEnabled",
  DEFAULT_FACING_MODE_ENVIRONMENT = "defaultFacingModeEnvironment",
  FILE_TRANSFER_ENABLED = "fileTransferEnabled",
  DISCLAIMER_ENABLED = "disclaimerEnabled",
  DISCLAIMER_TEXT = "disclaimerText",
  DISCLAIMER_VERSION = "disclaimerVersion",
  DEVICE_SETUP_ENABLED = "deviceSetupEnabled",
  TOP_BAR_ENABLED = "topBarEnabled",
  MEDIA_CONTROLS_ENABLED = "mediaControlsEnabled",
  PARTICIPANT_CONTROLS_ENABLED = "participantControlsEnabled",
  MEDIA_CONTROL_SIZE = "mediaControlSize",
  LEAVE_CONTROL_ENABLED = "leaveControlEnabled",
  LEAVE_CONTROL_WIDGET_ENABLED = "leaveControlWidgetEnabled",
  CAMERA_ENABLED = "cameraEnabled",
  MICROPHONE_ENABLED = "microphoneEnabled",
  CAMERA_DEFAULT_ON = "cameraDefaultOn",
  MICROPHONE_DEFAULT_ON = "microphoneDefaultOn",
  DISPLAY_NAME_REQUIRED = "displayNameRequired",
  /**
   * @deprecated. use PublicParam instead
   */
  INTERACTION_DATA_VISIBLE = "interactionDataVisible",
  AGENT_LEFT_TIMEOUT = "agentLeftTimeout",
  MIRROR_USER_CAM_ENABLED = "mirrorUserCamEnabled",
  AUTO_START_SECONDARY_CAMERA = "autoStartSecondaryCamera",
}

export interface IApplicationCustomerParams {
  [CustomerParam.RATING_REQUIRED]?: boolean;
  [CustomerParam.SCREEN_SHARE_ENABLED]?: boolean;
  [CustomerParam.DEFAULT_FACING_MODE_ENVIRONMENT]?: boolean;
  [CustomerParam.FILE_TRANSFER_ENABLED]?: boolean;
  [CustomerParam.DISCLAIMER_ENABLED]?: boolean;
  [CustomerParam.DISCLAIMER_TEXT]?: string;
  [CustomerParam.DISCLAIMER_VERSION]?: number;
  [CustomerParam.DEVICE_SETUP_ENABLED]?: boolean;
  [CustomerParam.TOP_BAR_ENABLED]?: boolean;
  [CustomerParam.MEDIA_CONTROLS_ENABLED]?: boolean;
  [CustomerParam.PARTICIPANT_CONTROLS_ENABLED]?: boolean;
  [CustomerParam.MEDIA_CONTROL_SIZE]?: SizeEnum;
  [CustomerParam.LEAVE_CONTROL_ENABLED]?: boolean;
  [CustomerParam.LEAVE_CONTROL_WIDGET_ENABLED]?: boolean;
  [CustomerParam.CAMERA_ENABLED]?: boolean;
  [CustomerParam.MICROPHONE_ENABLED]?: boolean;
  [CustomerParam.CAMERA_DEFAULT_ON]?: boolean;
  [CustomerParam.MICROPHONE_DEFAULT_ON]?: boolean;
  [CustomerParam.SPEAKER_ENABLED]?: boolean;
  [CustomerParam.DISPLAY_NAME_REQUIRED]?: boolean;
  [CustomerParam.INTERACTION_DATA_VISIBLE]?: boolean;
  [CustomerParam.AGENT_LEFT_TIMEOUT]?: number;
  [CustomerParam.MIRROR_USER_CAM_ENABLED]?: boolean;
  [CustomerParam.AUTO_START_SECONDARY_CAMERA]?: boolean;
}

/** ONLY role: CUSTOMER can see this */
export class CustomerOptions implements IApplicationCustomerParams {
  public [CustomerParam.RATING_REQUIRED] = false;
  public [CustomerParam.SCREEN_SHARE_ENABLED] = true;
  public [CustomerParam.DEFAULT_FACING_MODE_ENVIRONMENT] = false;
  public [CustomerParam.FILE_TRANSFER_ENABLED] = false;
  public [CustomerParam.DISCLAIMER_ENABLED] = false;
  public [CustomerParam.DISCLAIMER_TEXT] = null;
  public [CustomerParam.DISCLAIMER_VERSION] = 0;
  public [CustomerParam.DEVICE_SETUP_ENABLED] = true;
  public [CustomerParam.TOP_BAR_ENABLED] = true;
  public [CustomerParam.MEDIA_CONTROLS_ENABLED] = true;
  public [CustomerParam.PARTICIPANT_CONTROLS_ENABLED] = true;
  public [CustomerParam.MEDIA_CONTROL_SIZE] = SizeEnum.small;
  public [CustomerParam.LEAVE_CONTROL_ENABLED] = true;
  public [CustomerParam.LEAVE_CONTROL_WIDGET_ENABLED] = false;
  public [CustomerParam.CAMERA_ENABLED] = true;
  public [CustomerParam.MICROPHONE_ENABLED] = true;
  public [CustomerParam.CAMERA_DEFAULT_ON] = true;
  public [CustomerParam.MICROPHONE_DEFAULT_ON] = true;
  public [CustomerParam.SPEAKER_ENABLED] = true;
  public [CustomerParam.DISPLAY_NAME_REQUIRED] = false;
  public [CustomerParam.INTERACTION_DATA_VISIBLE] = false;
  public [CustomerParam.AGENT_LEFT_TIMEOUT] = null;
  public [CustomerParam.MIRROR_USER_CAM_ENABLED] = true;
  public [CustomerParam.AUTO_START_SECONDARY_CAMERA] = false;

  static create(options: IApplicationCustomerParams) {
    const instance = new CustomerOptions();
    return Object.assign(instance, options);
  }
}
