import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Optional,
  AfterViewInit,
} from "@angular/core";
import { IPoint, IArea, IPosition } from "../../models";
import { PointerService, DeviceService } from "../../services";
import { zoomInOut } from "../../core-ui.animations";
import { PointAreaComponent } from "../point-area/point-area.component";
import { COLOR, ColorEnum } from "../../core-ui.enums";
import { TinyColor } from "@ctrl/tinycolor";

@Component({
  selector: "app-point",
  templateUrl: "./point.component.html",
  styleUrls: ["./point.component.scss"],
  animations: [zoomInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: IPoint;
  @Input() area: IArea;
  @Input() streamId: string;

  @Output() destroyed = new EventEmitter<IPoint>();

  @ViewChild("svg", { static: true }) svgRef: ElementRef<HTMLOrSVGElement>;
  @ViewChild("circle", { static: true })
  circleRef: ElementRef<HTMLOrSVGElement>;
  @ViewChild("ring", { static: true }) ringRef: ElementRef<HTMLDivElement>;
  @ViewChild("point", { static: true }) pointRef: ElementRef<HTMLDivElement>;

  autoDestroyIn = 800;
  position: IPosition;
  timer;
  countdownTimer;

  normalizedRadius: number;
  circumference: number;
  radius = 25;
  stroke = 4;

  strokeDashoffset: number;

  progress = 100;

  constructor(
    private pointerService: PointerService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    @Optional() private parent: PointAreaComponent
  ) {
    this.normalizedRadius = this.radius - this.stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
    window.addEventListener("resize", this.resize.bind(this));
  }
  // using hostListener is slower
  // @HostListener('window:resize', ['$event'])
  resize() {
    this.position = this.pointerService.draw(this.parent.area, this.data);
    this.cdr.detectChanges();
  }

  ngOnInit() {
    if (!!this.data) {
      this.position = this.pointerService.draw(this.area, this.data);
      const color = new TinyColor(this.data.color || COLOR.RED);

      // this.pointerService.draw2(this.area, this.data, this.streamId)
      // .subscribe(position => {
      //   this.position = position;
      //   this.cdr.detectChanges();
      // });

      if (DeviceService.isSafari) {
        this.renderer.setStyle(this.ringRef.nativeElement, "left", "-10px");
      }

      this.renderer.setAttribute(
        this.svgRef.nativeElement,
        "height",
        String(this.radius * 2)
      );
      this.renderer.setAttribute(
        this.svgRef.nativeElement,
        "width",
        String(this.radius * 2)
      );
      this.renderer.setAttribute(this.svgRef.nativeElement, "id", this.data.id);

      this.renderer.setStyle(
        this.circleRef.nativeElement,
        "strokeWidth",
        this.stroke
      );
      this.renderer.setStyle(
        this.circleRef.nativeElement,
        "strokeDasharray",
        this.circumference + " " + this.circumference
      );

      this.renderer.setAttribute(
        this.circleRef.nativeElement,
        "id",
        this.data.id
      );
      this.renderer.setAttribute(
        this.circleRef.nativeElement,
        "r",
        String(this.normalizedRadius)
      );
      this.renderer.setAttribute(
        this.circleRef.nativeElement,
        "cx",
        String(this.radius)
      );
      this.renderer.setAttribute(
        this.circleRef.nativeElement,
        "cy",
        String(this.radius)
      );
      // background: $color;
      this.renderer.setStyle(
        this.pointRef.nativeElement,
        "background-color",
        color.toRgbString()
      );
      // box-shadow: 0 0 0 transparentize($color, 0.6);
      this.renderer.setStyle(
        this.pointRef.nativeElement,
        "box-shadow",
        ` 0 0 0 ${color.setAlpha(0.6).toRgbString()}`
      );
      // circle { stroke: desaturate(darken($color, 20%), 40%); }
      this.renderer.setStyle(
        this.circleRef.nativeElement,
        "stroke",
        color.setAlpha(1).darken(20).desaturate(40).toRgbString()
      );

      this.setProgress(this.progress);
      this.setCountdown();
    }
  }

  ngAfterViewInit() {
    // for some reason, chrome didn't render the circle, so we set display to awake the view
    this.renderer.setStyle(this.circleRef.nativeElement, "display", "flex");
  }

  setProgress(percent) {
    const offset = this.circumference - (percent / 100) * this.circumference;
    this.strokeDashoffset = offset;
    this.renderer.setStyle(
      this.circleRef.nativeElement,
      "strokeDashoffset",
      this.strokeDashoffset
    );
  }

  setCountdown() {
    this.countdownTimer = setInterval(() => {
      if (this.progress >= 0) {
        this.progress -= 100 / this.data.timeToLive;
        this.setProgress(this.progress);
      } else {
        clearInterval(this.countdownTimer);
      }
      this.cdr.detectChanges();
    }, 1000);
    this.timer = setTimeout(
      () => this.destroyed.emit(this.data),
      this.data.timeToLive * 1000 + 300
    );
  }

  clicked(event) {
    event.stopPropagation();
    // for future use. must also send event to refresh the remote points as well
    // clearInterval(this.countdownTimer);
    // clearTimeout(this.timer);
    // this.progress = 100;
    // this.setCountdown();
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    clearInterval(this.countdownTimer);
    // this.pointerService.untrack(this.data);
  }
}
